import React, { useState, useContext } from 'react'
import parse from 'html-react-parser'
import { BlocksContext } from "../context/blocksContext"

export const Header = () => {
	const [title,setTitle] = useState("");
	const blocks = useContext(BlocksContext)
	const getTitle = (paths) =>{
        let utm={}
		if(paths.length>0){
			if (paths.split('&').length>0){
				let params = paths.split('&')
				params.forEach((param)=>{
					param = param.split('=')
					if(param[0]=="utm_medium"){
						if (param[1]!=null){
							fetch("https://этосветлоград.рф/titles.php?utm_medium="+param[1], {})
								.then(data => data.ok && data.json())
								.then(response => {
									setTitle(parse(response.title))
								});
						}
					}
				})
			}
		} else {
			fetch("https://этосветлоград.рф/titles.php", {})
							.then(data => data.ok && data.json())
							.then(response => {
								setTitle(parse(response.title))
							});
		}
        return utm
    }
	const scrollToAnkor = (ankor, scrollMore) =>{
		blocks.setMenuClick(true)
		blocks.setBlocks(20)
		setTimeout(()=>{
			if(document.getElementById(ankor)!==null){
				window.scrollTo({
					top: document.getElementById(ankor).offsetTop+ scrollMore,
					behavior: "smooth"
				})
			}
			setTimeout(()=>{
				blocks.setMenuClick(false)
			},500)

		},1500)
	}
	if (title.length ==0){
		let url = window.location.toString().split("?")
		if (url.length>1 && title.length==0){
			getTitle(url[1])
		} else {
			getTitle([])
		}
		if(window.location.toString().split("#").length>1){
			let ankor = ""
			let tmp = window.location.toString().split("#")[1]
			let i=0;
			while('&?/'.indexOf(tmp[i])===-1 && i<tmp.length){
				ankor+=tmp[i]
				i++
			}
			let scrollMore=0;
			if (ankor == "ankor3"){
				ankor= "ankor3"
				scrollMore = -200
			}

			scrollToAnkor(ankor, scrollMore)

		}
	}



    return (
        <section class="header">

			{title.length==0?<div class="header_inner" style={{top: "0px"}}>
				<div class="hd_dark_dec"></div>
				<h1>ЖК «Светлоград»</h1>
				<sub>Светлый и просторный жилой комплекс<br /> в Краснодаре. Детский сад и школа.<br /> Закрытый двор без машин</sub>
				<div class="hd_ds dec_square">
					<div class="ds_anim">
						<span>КВАРТИРЫ ОТ</span>
						<b>2.9</b>
						<p> МЛН РУБ.</p>
					</div>
				</div>
				<img src="img/scroll_ico.png" alt="" class="scroll_ico" />
			</div>:title}
			{/* <div data-marquiz-id="602a451331a490004438d5df"></div> */}
		</section>
    )
}
