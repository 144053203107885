import {React} from "react"
export const Advantages = () => {
    return (
        <section className="advantages">
			<div className="advant_wrap">
				<div className="advant_sect"><div className="advant_tit"><span>Квартира одна -<br /> преимуществ много</span></div></div>
				<div className="advant_sect adv_tit_animate"><div className="advant_tit"><span>Удобная<br /> форма кухни</span></div><img src="img/advant_kitchen.jpg" /></div>
				<div className="advant_sect adv_tit_animate"><div className="advant_tit"><span>Просторные<br /> балконы <br /> до 4,24м<sup>2</sup></span></div><img src="img/advant_balcon.jpg" /></div>
				<div className="advant_sect adv_tit_animate"><div className="advant_tit"><span>Большие ванные <br /> от 4 до 6м<sup>2</sup></span></div><img src="img/advant_bathroom.jpg" /></div>
			</div>
		</section>
    )
}