import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

export const Viewing = () => {
    const TextInput = ({ name, label }) => {
        return <TextField name={name} label={label} style={{ width: '100%' }} />;
    };
    const addError = (element) => {
        element.parentElement.parentElement.querySelector('label').classList.add('Mui-error');
        element.parentElement.parentElement.querySelector('input').parentElement.classList.add('Mui-error');
    };
    const checkPhone = (element) => {
        if (element.value.indexOf('_') != -1 || element.value.length == 0) {
            addError(element);
            return false;
        }
        return true;
    };

    const checkEmail = (element) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(element.value).toLowerCase());
    };
    const showAlert = (data, celtype) => {
        gtag('event', 'send', {
            event_category: 'forms',
            event_label: celtype,
            value: 1
        });
        gtag('event', 'send', {
            event_category: 'forms',
            event_label: 'vse',
            value: 1
        });
        ym(40310715, 'reachGoal', celtype);
        ym(40310715, 'reachGoal', 'vse');
        fbq('trackCustom', 'vse');
        VK.Goal('lead');
        document.querySelector('.popup_rgba').style.display = 'block';
        document.querySelectorAll('.popup_main').forEach((elem) => {
            elem.style.display = 'none';
        });
        document.querySelector('body').classList.add('overflow');
        document.querySelector('.pu_thx').style.display = 'block';
        fetch('lrcnt_react.php', {})
            .then((data) => data.ok && data.json())
            .then((response) => {
                data = { ...data, ...response };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };
                fetch('fd_log/main.php', requestOptions).then((data) => data.ok);
            });
    };

    const sendForm = (e) => {
        e.preventDefault();
        let flag = true;
        let form = e.target.closest('form');
        let celtype = e.currentTarget.getAttribute('celtype');
        let name = form.querySelector('input[name="name"]').value;
        let phone = form.querySelector('input[name="phone"]').value;
        let url = window.location.toString().split('?');
        let utm = null;
        if (url.length > 1) {
            utm = getUtms(url[1]);
        }

        if (!checkPhone(form.querySelector('input[name="phone"]'))) {
            flag = false;
        }
        if (form.querySelector('input[name="email"]') != null) {
            if (!checkEmail(form.querySelector('input[name="email"]'))) {
                if (form.querySelector('input[name="email"]').getAttribute('req') == 'Y') {
                    flag = false;
                }
            }
        }
        let text = form.querySelector('input.text').value;
        form.querySelectorAll('.dop-info').forEach((el) => {
            if (el.value.length != 0 && el.getAttribute('date') != null)
                text += ';' + el.getAttribute('date') + ':' + el.value;
        });

        if (flag) {
            let ClientID;
            ym(40310715, 'getClientID', function (clientID) {
                ClientID = clientID;
            });
            let senddata = {
                getCall: 'Y',
                celtype: celtype,
                name: name,
                phone: phone,
                ClientID: ClientID,
                text: text,
                ...utm
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(senddata)
            };

            fetch('fd_log/ajax.php', requestOptions);
            // .then(data => data.ok)
            // .then(response => {
            // });
            showAlert(senddata, celtype);
        }
    };
    return (
        <section className="viewing" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div className="view_tit">
                Запишитесь на
                <br /> просмотр
            </div>
            <form className="form_main react_input_style" style={{ display: 'flex', flexDirection: 'row' }}>
                <TextInput name="name" label="Имя" />
                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                    <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                </InputMask>
                <input type="hidden" className="text" value="Записаться на просмотр квартиры" />
                <button className="btn_view lead-btn" onClick={sendForm} celtype="getProsmotr">
                    <span>Записаться</span>
                </button>
            </form>
            <p style={{ margin: '0 auto', marginTop: 2.04 + 'vw', fontSize: '16px', color: '#fff' }}>
                Отправляя форму, я даю согласие на{' '}
                <a href="/policy.pdf" target="_blank" style={{ color: '#fff' }}>
                    обработку персональных данных
                </a>
            </p>
        </section>
    );
};
