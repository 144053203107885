import React, { useEffect, useState, useContext } from 'react';
import { BlocksContext } from '../context/blocksContext';

export const Menu = () => {
    const [icon, setIcon] = useState(false);
    const [collapseMenu, setCollapseMenu] = useState(false);
    const blocks = useContext(BlocksContext);

    const handleScroll = (event) => {
        if (window.scrollY < 30 && !collapseMenu) {
            setCollapseMenu(false);
        } else {
            setCollapseMenu(true);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        const interval = setInterval(() => {
            clearInterval(interval);
            setIcon(!icon);
        }, 5000);
    }, []);

    const showPopup = (e) => {
        e.preventDefault();
        if (!blocks.popup) {
            blocks.setPopup(true);
            setTimeout(() => {
                let popup = e.target.getAttribute('data-popup');
                document.querySelector('.popup_rgba').style.display = 'block';
                document.querySelectorAll('.popup_rgba img').forEach((el) => {
                    if (el.getAttribute('src') == null && el.getAttribute('data-src') != null) {
                        el.setAttribute('src', el.getAttribute('data-src'));
                    }
                });
                document.querySelectorAll('.popup_main').forEach((el) => (el.style.display = 'none'));

                document.querySelector('.popup_main.' + popup).style.display = 'block';
                document.querySelector('body').classList.add('overflow');
            }, 500);
        } else {
            let popup = e.target.getAttribute('data-popup');
            document.querySelector('.popup_rgba').style.display = 'block';
            document.querySelectorAll('.popup_rgba img').forEach((el) => {
                if (el.getAttribute('src') == null && el.getAttribute('data-src') != null) {
                    el.setAttribute('src', el.getAttribute('data-src'));
                }
            });
            document.querySelectorAll('.popup_main').forEach((el) => (el.style.display = 'none'));

            document.querySelector('.popup_main.' + popup).style.display = 'block';
            document.querySelector('body').classList.add('overflow');
        }
    };

    const clickMenu = (e) => {
        e.preventDefault();
        let popup = e.currentTarget.getAttribute('data');
        let num = parseInt(e.currentTarget.getAttribute('num'));

        if (blocks.blocks < num) {
            blocks.setMenuClick(true);
            blocks.setBlocks(num + 1);
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector('.' + popup).offsetTop,
                    behavior: 'smooth'
                });
                setTimeout(() => {
                    blocks.setMenuClick(false);
                }, 500);
            }, 2000);
        } else {
            window.scrollTo({
                top: document.querySelector('.' + e.currentTarget.getAttribute('data')).offsetTop,
                behavior: 'smooth'
            });
        }
        if (document.querySelector('.hl_nav_btn').classList.contains('opened')) {
            document.querySelector('.hl_nav_btn').classList.remove('opened');
            document.querySelector('.hl_top_mob_nav').classList.remove('opened');
        }
    };

    const sandwichMenuClick = (e) => {
        e.preventDefault();
        if (e.target.classList.contains('opened')) {
            e.target.classList.remove('opened');
            document.querySelector('.hl_top_mob_nav').classList.remove('opened');
        } else {
            e.target.classList.add('opened');
            document.querySelector('.hl_top_mob_nav').classList.add('opened');
        }
    };
    return (
        <div clasName="menu">
            <section className={!collapseMenu ? 'hl_left' : 'hl_left hl_left_act'}>
                <div className="logo">{/*<img src="img/main_logo.png">*/}</div>

                <ul className="hl_nav">
                    <li>
                        <a onClick={clickMenu} num="4" href="#" data="gallery">
                            <p className="menu_ico gal_menu"></p>
                            <span>Галерея</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={clickMenu} num="6" href="#" data="infra">
                            <p className="menu_ico infra_menu"></p>
                            <span>Инфраструктура</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={clickMenu} num="11" href="#" data="get_apart">
                            <p className="menu_ico plans_menu"></p>
                            <span>Планировки и цены</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={clickMenu} num="14" href="#" data="how_buy">
                            <p className="menu_ico buy_menu"></p>
                            <span>Способы покупки</span>
                        </a>
                    </li>
                    {/* <li>
                        <a onClick={clickMenu} num="17" href="#" data="action_offer">
                            <p className="menu_ico sales_menu"></p>
                            <span>Акции</span>
                        </a>
                    </li> */}
                    <li>
                        <a onClick={clickMenu} num="18" href="#" data="contacts">
                            <p className="menu_ico cont_menu"></p>
                            <span>Контакты</span>
                        </a>
                    </li>
                </ul>
                <a href="" className="hl_bot">
                    <img width={24} src="img/rebrending/logo3_white.svg" />
                    <span>
                        <b>
                            Официальный сайт ЖК "Светлоград"
                            <br />
                            Застройщик ООО "Семья", {new Date().getFullYear()}
                        </b>
                    </span>
                </a>
            </section>
            <ul className="hl_top_mob_nav">
                <li>
                    <a onClick={clickMenu} num="4" href="#" data="gallery">
                        <p className="menu_ico gal_menu"></p>
                        <span>Галерея</span>
                    </a>
                </li>
                <li>
                    <a onClick={clickMenu} num="6" href="#" data="infra">
                        <p className="menu_ico infra_menu"></p>
                        <span>Инфраструктура</span>
                    </a>
                </li>
                <li>
                    <a onClick={clickMenu} num="11" href="#" data="get_apart">
                        <p className="menu_ico plans_menu"></p>
                        <span>Планировки и цены</span>
                    </a>
                </li>
                <li>
                    <a onClick={clickMenu} num="14" href="#" data="how_buy">
                        <p className="menu_ico buy_menu"></p>
                        <span>Способы покупки</span>
                    </a>
                </li>
                {/* <li>
                    <a onClick={clickMenu} num="17" href="#" data="action_offer">
                        <p className="menu_ico sales_menu"></p>
                        <span>Акции</span>
                    </a>
                </li> */}
                <li>
                    <a onClick={clickMenu} num="18" href="#" data="contacts">
                        <p className="menu_ico cont_menu"></p>
                        <span>Контакты</span>
                    </a>
                </li>
            </ul>
            <section className={!collapseMenu ? 'hl_top' : 'hl_top hl_top_act'}>
                <div onClick={sandwichMenuClick} className="hl_nav_btn"></div>
                <div className="hl_top_info">
                    <span href="#">36 домов сдано </span>
                    <span href="#">Комплекс построен</span>
                    {/* <span href="#">15 минут до центра</span> */}
                </div>
                <div className="hl_right">
                    <div className="hlt_develop">
                        <div className="hlt_in hltd">
                            <img src={process.env.PUBLIC_URL + '/img/rebrending/logo2.svg'} />
                            <span>
                                Надежный
                                <br /> застройщик
                            </span>
                        </div>
                        <div className="hlt_in hltf">
                            <img src={process.env.PUBLIC_URL + '/img/rebrending/logo2.svg'} />
                            <span>СК Семья</span>
                        </div>
                        {/*<div className="hlt_in hltf" >
                            <img src= { process.env.PUBLIC_URL +"/img/rebrending/logo3.svg"} />
                            <div style={{width:"60%"}}>
                                <span style={{display: icon?"block":"none"}}>Надежный<br /> застройщик</span>
                                <span style={{display: icon?"none":"block"}}>СК Семья</span>
                            </div>
                        </div>*/}
                    </div>
                    <a href="tel:+788612056825" className="hlt_numb roistat-phone">
                        8 800 222 81 81
                    </a>
                    <a href="#" className="hlt_call show_popup" onClick={showPopup} data-popup="pu_call">
                        Заказать звонок
                    </a>
                </div>
                <div className="clr"></div>
            </section>
        </div>
    );
};
