import { React, useEffect, useState } from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Slider from '@material-ui/core/Slider';
import InputMask from 'react-input-mask';
//<InputMask className="form-control required" mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >

export const Popups = () => {
    const btnCloseClick = (e) => {
        e.preventDefault();
        if (!e.target.parentElement.classList.contains('return')) {
            document.querySelector('.popup_rgba').style.display = 'none';
            document.querySelectorAll('.popup_main').forEach((el) => (el.style.display = 'none'));
            document.querySelector('body').classList.remove('overflow');
            setFloorP(2);
            setFloor(2);
        } else {
            document.querySelector('.popup_main.pu_flat').classList.remove('return');
            document.querySelector('.popup_main.pu_flat').style.display = 'none';
            document.querySelector('.popup_main.pu_floor').style.display = 'block';
        }
    };

    const [floor, setFloor] = useState(17);
    const [floor_p, setFloorP] = useState(2);
    const [floor_m, setFloorM] = useState(19);
    const [name_, setName] = useState('');
    const [callTime, setCallTime] = useState('');
    const onChangeName = (e) => {
        setName(e.target.value);
        setTimeout(
            () =>
                document.querySelectorAll('.popup_rgba .popup_main').forEach((elem) => {
                    if (elem.style.display == 'block') {
                        elem.querySelector('input[name="name"]').focus();
                    }
                }),
            0
        );
    };

    const TextInput = ({ name, label }) => {
        if ((name = 'name')) {
            return (
                <TextField name={name} label={label} value={name_} onChange={onChangeName} style={{ width: '100%' }} />
            );
        } else {
            return <TextField name={name} label={label} style={{ width: '100%' }} />;
        }
    };

    const handleChangeFloor = (event, newValue) => {
        setFloor(newValue);
    };
    const handleChangeFloorP = (event, newValue) => {
        setFloorP(newValue);
        let section = document.querySelector('.pu_entrance span.act').textContent;
        let liter = document.querySelector('.popup_main.pu_floor .pu_tm i').textContent;
        let floors = 2;
        document
            .querySelectorAll(
                ".popup_main.pu_floor .pu_floor_img[data-section='" + section + "'][data-liter='" + liter + "']"
            )
            .forEach((el) => {
                floors =
                    floors < parseInt(el.getAttribute('data-floors').split('-')[1])
                        ? parseInt(el.getAttribute('data-floors').split('-')[1])
                        : floors;
            });
        document.querySelectorAll('.popup_main.pu_floor .pu_floor_img').forEach((el) => {
            el.style.display = 'none';
        });

        document
            .querySelectorAll(
                ".popup_main.pu_floor .pu_floor_img[data-section='" + section + "'][data-liter='" + liter + "']"
            )
            .forEach((el) => {
                if (
                    floor_p <= parseInt(el.getAttribute('data-floors').split('-')[1]) &&
                    floor_p >= parseInt(el.getAttribute('data-floors').split('-')[0])
                ) {
                    el.querySelector('img').setAttribute('src', el.querySelector('img').getAttribute('data-src'));
                    el.style.display = 'block';
                }
            });
        setFloorM(floors);
    };

    const plansSectionClick = (e) => {
        document.querySelectorAll('.pu_entrance span').forEach((el) => {
            el.classList.remove('act');
        });
        e.target.classList.add('act');
        let section = document.querySelector('.pu_entrance span.act').textContent;
        let liter = document.querySelector('.popup_main.pu_floor .pu_tm i').textContent;
        let floors = 2;
        document
            .querySelectorAll(
                ".popup_main.pu_floor .pu_floor_img[data-section='" + section + "'][data-liter='" + liter + "']"
            )
            .forEach((el) => {
                floors =
                    floors < parseInt(el.getAttribute('data-floors').split('-')[1])
                        ? parseInt(el.getAttribute('data-floors').split('-')[1])
                        : floors;
            });
        document.querySelectorAll('.popup_main.pu_floor .pu_floor_img').forEach((el) => {
            el.style.display = 'none';
        });
        document
            .querySelector(
                ".popup_main.pu_floor .pu_floor_img[data-section='" + section + "'][data-liter='" + liter + "'] img"
            )
            .setAttribute(
                'src',
                document
                    .querySelector(
                        ".popup_main.pu_floor .pu_floor_img[data-section='" +
                            section +
                            "'][data-liter='" +
                            liter +
                            "'] img"
                    )
                    .getAttribute('data-src')
            );
        document.querySelector(
            ".popup_main.pu_floor .pu_floor_img[data-section='" + section + "'][data-liter='" + liter + "']"
        ).style.display = 'block';
        setFloorM(floors);
        setFloorP(2);
    };

    const floorClick = (e) => {
        e.preventDefault();
        const headers = { 'Content-Type': 'application/json' };
        let id = parseInt(e.target.classList[1].split('_')[1]);
        let liter_id = e.target.parentNode.getAttribute('data-liter');
        fetch('flats_react.php?id=' + id, headers)
            .then((res) => res.json())
            .then((el) => {
                let popup = 'pu_flat';
                document.querySelector('.popup_main.pu_flat .pu_tm').innerHTML = el['title'];
                document.querySelector('.popup_main.pu_flat .fl_total_area i').innerHTML = el['info'];
                document.querySelector('.popup_main.pu_flat .fl_living_area i').innerHTML = el['zhil'];
                document.querySelector('.popup_main.pu_flat .pfi_in img').setAttribute('src', el['img_prew']);
                document.querySelector(".popup_main.pu_flat input[type='hidden']").value =
                    'Узнать стоимость квартиры. ' +
                    el['title'] +
                    '; Литер: ' +
                    liter_id +
                    '; Общая площадь: ' +
                    el['info'] +
                    ' м2; жилая площадь: ' +
                    el['zhil'] +
                    ' м2;';

                document.querySelector('.popup_rgba').style.display = 'block';
                document.querySelectorAll('.popup_main.pu_flat img').forEach((el) => {
                    if (el.getAttribute('src') == null && el.getAttribute('data-src') != null) {
                        el.setAttribute('src', el.getAttribute('data-src'));
                    }
                });
                document.querySelectorAll('.popup_main').forEach((el) => (el.style.display = 'none'));
                document.querySelector('.popup_main.' + popup).classList.add('return');
                document.querySelector('.popup_main.' + popup).style.display = 'block';
                document.querySelector('body').classList.add('overflow');
            });
    };
    const addError = (element) => {
        element.parentElement.parentElement.querySelector('label').classList.add('Mui-error');
        element.parentElement.parentElement.querySelector('input').parentElement.classList.add('Mui-error');
    };
    const checkPhone = (element) => {
        if (element.value.indexOf('_') != -1 || element.value.length == 0) {
            addError(element);
            return false;
        }
        return true;
    };

    const checkEmail = (element) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(element.value).toLowerCase());
    };
    const getUtms = (paths) => {
        let utm = {};
        if (paths.split('&').length > 0) {
            let params = paths.split('&');
            params.forEach((param) => {
                param = param.split('=');
                if (
                    param[0] == 'utm_medium' ||
                    param[0] == 'utm_content' ||
                    param[0] == 'utm_campaign' ||
                    param[0] == 'utm_term' ||
                    param[0] == 'utm_source'
                ) {
                    utm = { ...utm, [param[0]]: param[1] };
                }
            });
        }
        return utm;
    };

    const showAlert = (data, celtype) => {
        gtag('event', 'send', {
            event_category: 'forms',
            event_label: celtype,
            value: 1
        });
        gtag('event', 'send', {
            event_category: 'forms',
            event_label: 'vse',
            value: 1
        });
        ym(40310715, 'reachGoal', celtype);
        ym(40310715, 'reachGoal', 'vse');

        fbq('trackCustom', 'vse');
        VK.Goal('lead');
        document.querySelectorAll('.popup_main').forEach((elem) => {
            elem.style.display = 'none';
        });
        document.querySelector('.pu_thx').style.display = 'block';
        fetch('lrcnt_react.php', {})
            .then((data) => data.ok && data.json())
            .then((response) => {
                data = { ...data, ...response };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };
                fetch('fd_log/main.php', requestOptions).then((data) => data.ok);
            });
    };

    const sendForm = (e) => {
        e.preventDefault();
        let flag = true;
        let form = e.target.closest('form');
        let celtype = e.currentTarget.getAttribute('celtype');
        let name = form.querySelector('input[name="name"]').value;
        let phone = form.querySelector('input[name="phone"]').value;
        let url = window.location.toString().split('?');
        let utm = null;
        if (url.length > 1) {
            utm = getUtms(url[1]);
        }

        if (!checkPhone(form.querySelector('input[name="phone"]'))) {
            flag = false;
        }
        if (form.querySelector('input[name="email"]') != null) {
            if (!checkEmail(form.querySelector('input[name="email"]'))) {
                if (form.querySelector('input[name="email"]').getAttribute('req') == 'Y') {
                    flag = false;
                }
            }
        }
        let text = form.querySelector('input.text').value;
        form.querySelectorAll('.dop-info').forEach((el) => {
            if (el.value.length != 0 && el.getAttribute('date') != null)
                text += ';' + el.getAttribute('date') + ':' + el.value;
        });

        if (flag) {
            let ClientID;
            ym(40310715, 'getClientID', function (clientID) {
                ClientID = clientID;
            });
            let senddata = {
                getCall: 'Y',
                celtype: celtype,
                name: name,
                phone: phone,
                ClientID: ClientID,
                text: text,
                ...utm
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(senddata)
            };

            fetch('fd_log/ajax.php', requestOptions);
            // .then(data => data.ok)
            // .then(response => {
            // });
            showAlert(senddata, celtype);
        }
    };

    const callSelectHandler = (e, value) => {
        setCallTime(e.target.selectedOptions[0].value);
    };

    const handleChangeSrok = (e, value) => {
        if (e.target.closest('form') != null) {
            e.target.closest('form').querySelector('input.srok').value = value + ' лет';
            {
                /*e.target.closest('form').querySelector('.ui-slider-handle').innerHTML = value+" лет"*/
            }
        }
    };
    const handleChangePerv = (e, value) => {
        if (e.target.closest('form') != null) {
            e.target.closest('form').querySelector('input.vznos').value = e.target.selectedOptions[0].value;
            {
                /*e.target.closest('form').querySelector('.ui-slider-handle').innerHTML = value+" лет"*/
            }
        }
    };

    const textAreaChange = (e) => {
        document.querySelector('input.question').value = e.target.value;
    };

    function isDescendant(child) {
        var node = child.parentNode;
        while (node.classList != undefined) {
            if (node.classList.contains('popup_main')) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    useEffect(() => {
        {
            /*$(document).click(function(e) {
            console.log(e.target)
            var div = $(".popup_main");
            if (!div.is(e.target) && div.has(e.target).length === 0 && div.is(":visible") && e.target.nodeName.toLowerCase()!='path' && e.target.nodeName.toLowerCase()!='i' && !e.target.classList.contains('lit_ident')) {
                $("body").removeClass("overflow");
                $(".popup_rgba").hide();
                $(".popup_main").hide();
                console.log(123)
            }
        });*/
        }
        document.querySelector('.blocks').addEventListener('click', (e) => {
            let clicked = e.target;
            if (
                !clicked.classList.contains('popup_main') &&
                !isDescendant(clicked) &&
                e.target.nodeName.toLowerCase() != 'canvas'
            ) {
                document.querySelector('body').classList.remove('overflow');
                document.querySelector('.popup_rgba').style.display = 'none';
                document.querySelector('.popup_main').style.display = 'none';
            }
        });
    }, []);

    return (
        <div className="popup_rgba" style={{ display: 'none' }}>
            <div className="popup_table">
                <div className="popup_cell">
                    <div className="popup_main pu_ck" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <strong>Политика использования файлов «Cookie»</strong>
                        <br />
                        <br />
                        <p>
                            Файлы «Cookie» представляют собой небольшие фрагменты информации, которые размещаются на
                            вашем компьютере при посещении определенных веб-сайтов. Файлы «Cookie» используются для
                            улучшения персонализации и интерактивности в предоставлении информации на сайте.
                        </p>
                        <p>
                            При первом посещении данного сайта, с помощью нового браузера или в режиме приватного
                            просмотра предоставляется баннер, запрашивающий ваше согласие на обработку файлов «Cookie» в
                            соответствии с требованиями законодательства. Нажав кнопку «Принять» или продолжая
                            пользоваться данным сайтом, вы соглашаетесь на размещение файлов «Cookie».
                        </p>
                        <p>
                            Используются два вида файлов «Cookie»: временные (сессионные), срок действия которых
                            истекает после закрытия интернет-обозревателя, и бессрочные, которые при необходимости могут
                            быть удалены пользователем из его компьютера.
                        </p>
                        <p>
                            Использование файлов «Cookie» может быть отключено в интернет-обозревателе (просим вас
                            ознакомиться с данной возможностью в разделе «Справка» вашего браузера). При отключении
                            использования файлов «Cookie» могут быть недоступны некоторые функции сайта.
                        </p>
                        <p>
                            Сторонние организации не имеют доступа к файлам «Cookie» нашего сайта. Сторонние организации
                            (например, Microsoft, Google, Yandex и т.п.), которые размещают собственные файлы «Cookie»,
                            включая ваш браузер, имеют собственные политики использования файлов «Cookie».
                        </p>
                    </div>

                    <div className="popup_main pu_call" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">Закажите звонок</div>
                        <form action="" className="pu_form form_main react_input_style">
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <FormControl style={{ width: '100%' }}>
                                <NativeSelect
                                    name={'time-pick'}
                                    onChange={callSelectHandler}
                                    className="react_select_style"
                                >
                                    <option value={''}>Удобное время для звонка</option>
                                    <option value={'9:00 - 12:00'}>9:00 - 12:00</option>
                                    <option value="12:00 - 14:00">12:00 - 14:00</option>
                                    <option value="14:00 - 16:00">14:00 - 16:00</option>
                                    <option value="16:00 - 18:00">16:00 - 18:00</option>
                                </NativeSelect>
                            </FormControl>
                            <input type="hidden" className="text" value="Заказать звонок" />
                            <input
                                type="hidden"
                                className="dop-info time-pick"
                                date="Удобное время для звонка"
                                value={callTime}
                            />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getCall" template="2">
                                <span>Заказать звонок</span>
                            </button>
                        </form>
                    </div>

                    <div className="popup_main pu_akc" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">
                            Узнайте подробнее <br /> об условиях акции
                        </div>
                        <form action="" className="pu_form form_main react_input_style">
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <input type="hidden" className="text" value="Узнать подробнее об условиях акции" />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getAkc" template="2">
                                <span>Узнать подробнее</span>
                            </button>
                        </form>
                    </div>
                    <div className="popup_main pu_matkap" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">Материнский капитал</div>
                        <form action="" className="pu_form form_main react_input_style">
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <input type="hidden" className="text" value="Узнать подробнее материнский капитал" />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getMatKap" template="2">
                                <span>Узнать подробнее</span>
                            </button>
                        </form>
                    </div>

                    <div className="popup_main pu_rassr" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">Рассрочка</div>
                        <form action="" className="pu_form form_main react_input_style">
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <input type="hidden" className="text" value="Узнать подробнее рассрочка" />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getRassr">
                                <span>Узнать подробнее</span>
                            </button>
                        </form>
                    </div>

                    <div className="popup_main pu_quest" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">Задать вопрос</div>
                        <form action="" className="pu_form form_main react_input_style">
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <input type="hidden" className="text" value="Задать вопрос" />
                            <input
                                type="hidden"
                                className="dop-info question"
                                date="Текст вопроса"
                                value="Задать вопрос"
                            />
                            {/*<div className="pu_pl pu_tarea">
                                <TextareaAutosize style={{minHeight:"70px"}} className="dop-info" date="Текст вопроса" rowsMin={2} aria-label="empty textarea" placeholder="Текст вопроса" />
                            </div>	*/}
                            <TextField
                                label="Текст вопроса"
                                multiline
                                rowsMax={1}
                                onChange={textAreaChange}
                                className="react_textarea"
                            />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getQuest">
                                <span>Узнать подробнее</span>
                            </button>
                        </form>
                    </div>

                    <div className="popup_main pu_voen" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">Военная ипотека</div>
                        <form action="" className="pu_form form_main react_input_style">
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <input type="hidden" className="text" value="Узнать подробнее военная военная ипотека" />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getVoen">
                                <span>Узнать подробнее</span>
                            </button>
                        </form>
                    </div>

                    <div className="popup_main pu_thx" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">
                            Спасибо!{' '}
                            <p>
                                В ближайшее время с вами <br /> свяжется наш специалист.
                            </p>
                        </div>
                    </div>

                    <div className="popup_main pu_calc" style={{ display: 'none' }}>
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div className="pu_tm">
                            Получите расчет <p>ежемесячного платежа по ипотеке</p>
                        </div>
                        <form action="" className="pu_form form_main react_input_style">
                            <div className="pu_slid pu_slid_rooms">
                                <div className="ga_name">На срок:</div>
                                {/* <div className="time_slid slid_style single_slid_style ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all">
                                    <div className="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min" style={{width: "48%"}}>
                                    </div>
                                     <span className="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style={{left: "48%"}}>{5} лет</span>
                                </div>*/}
                                <Slider
                                    className="slid_style_react"
                                    //defaultValue={5}
                                    aria-labelledby="range-slider2"
                                    step={1}
                                    min={5}
                                    max={30}
                                    onChange={handleChangeSrok}
                                    defaultValue={5}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(value) => <div>{value + ' лет'}</div>}
                                />
                            </div>
                            <FormControl style={{ width: '100%' }}>
                                <NativeSelect
                                    name={'time-pick'}
                                    onChange={handleChangePerv}
                                    className="react_select_style"
                                >
                                    <option value={''}>Первоначальный взнос</option>
                                    <option value={'10%'}>10%</option>
                                    <option value="20%">20%</option>
                                    <option value="30%">30%</option>
                                    <option value="40%">40%</option>
                                    <option value="50%">50%</option>
                                </NativeSelect>
                            </FormControl>
                            <TextInput name="name" onChange={onChangeName} label="Имя" />
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                            </InputMask>
                            <input type="hidden" className="text" value="Получить расчет по ипотеке" />
                            <input type="hidden" className="dop-info vznos" date="Первоначальный взнос" />
                            <input type="hidden" className="dop-info srok" date="На срок" />
                            <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                Отправляя форму, я даю согласие на{' '}
                                <a href="/policy.pdf" target="_blank">
                                    обработку персональных данных
                                </a>
                            </p>
                            <button onClick={sendForm} className="btn_main lead-btn" celtype="getIpoteka">
                                <span>Получить расчет</span>
                            </button>
                        </form>
                    </div>

                    <div className="popup_main pu_flat" style={{ display: 'none' }}>
                        <a href="#" class="closeform" onClick={btnCloseClick}></a>
                        <div class="pu_tm"></div>
                        <div class="flat_area">
                            <span class="fl_total_area">
                                Общая площадь: <i></i> м<sup>2</sup>
                            </span>
                            <span class="fl_living_area">
                                Жилая площадь: <i></i> м<sup>2</sup>
                            </span>
                        </div>
                        <div class="pu_flat_wrap">
                            <div class="pfi_in">
                                <div class="pu_flat_img">
                                    <img src="" />
                                </div>
                            </div>
                            <form action="" class="pu_form form_main react_input_style">
                                <div class="pu_form_tit">Узнайте стоимость квартиры на сегодня </div>
                                <TextInput name="name" onChange={onChangeName} label="Имя" />
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                    <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                                </InputMask>
                                <input type="hidden" class="text" />
                                <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                                    Отправляя форму, я даю согласие на{' '}
                                    <a href="/policy.pdf" target="_blank">
                                        обработку персональных данных
                                    </a>
                                </p>
                                <button onClick={sendForm} class="btn_main lead-btn" celtype="getCost">
                                    <span>Узнать стоимость</span>
                                </button>
                            </form>
                            <div class="clr"></div>
                        </div>
                    </div>

                    <div className="popup_main pu_floor">
                        <a href="#" className="closeform" onClick={btnCloseClick}></a>
                        <div class="pu_tm">
                            <div class="title_floor">
                                Планировки <i>9</i> литера
                            </div>
                            <div class="renting_date">2 кв. 2021</div>
                        </div>
                        <div className="pu_floor_nav">
                            <div className="pu_slid">
                                <div className="pu_slid_wrap">
                                    <div className="ga_name">Этаж</div>
                                    {/*<div className="pu_floor_slid slid_style single_slid_style ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"><div className="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min" style={{width: "0%"}}></div><span className="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style={{left: "0%"}}>{floor_p}</span></div>*/}
                                    <Slider
                                        className="slid_style_react"
                                        defaultValue={floor_p}
                                        value={floor_p}
                                        aria-labelledby="range-slider2"
                                        step={1}
                                        min={2}
                                        max={floor_m}
                                        valueLabelDisplay="on"
                                        onChange={handleChangeFloorP}
                                    />
                                </div>
                            </div>
                            <div className="pu_entrace_wrap">
                                <div className="pu_entrance">
                                    <div className="ga_name">Подъезд</div>
                                    <span onClick={plansSectionClick} className="act" style={{ display: 'block' }}>
                                        1
                                    </span>
                                    <span onClick={plansSectionClick} style={{ display: 'block' }}>
                                        2
                                    </span>
                                    <span onClick={plansSectionClick} style={{ display: 'block' }}>
                                        3
                                    </span>
                                    <span onClick={plansSectionClick} style={{ display: 'block' }}>
                                        4
                                    </span>
                                    <span onClick={plansSectionClick} style={{ display: 'block' }}>
                                        5
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="19"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_1"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_2"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_3"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_4"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_6"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_7"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_8"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_9"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_10"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_11"></a>
                            <img data-src="img/plans/L-19_S-1.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="19"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_12"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_13"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_14"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_15"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_16"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_17"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_18"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_19"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_20"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_21"></a>
                            <img data-src="img/plans/L-19_S-2.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="20"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_22"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_23"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_24"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_25"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_26"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_27"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_28"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_29"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_30"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_31"></a>
                            <img data-src="img/plans/L-20_S-1.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="20"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_32"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_33"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_34"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_35"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_36"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_37"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_38"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_39"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_40"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_41"></a>
                            <img data-src="img/plans/L-20_S-2.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="21"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_42"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_43"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_44"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_45"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_46"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_47"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_48"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_49"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_50"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_51"></a>
                            <img data-src="img/plans/L-21_S-1.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="21"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_52"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_53"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_54"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_55"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_56"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_57"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_58"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_59"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_60"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_61"></a>
                            <img data-src="img/plans/L-21_S-2.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="22"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_62"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_63"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_64"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_65"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_66"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_67"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_68"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_69"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_70"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_71"></a>
                            <img data-src="img/plans/L-22_S-1.png" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="22"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_72"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_73"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_74"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_75"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_76"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_77"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_78"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_79"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_80"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_81"></a>
                            <img data-src="img/plans/L-22_S-2.png" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="6"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_82"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_83"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_84"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_85"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_86"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_87"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_88"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_89"></a>
                            <img data-src="img/plans/L-6_S-1.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="6"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_90"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_91"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_92"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_93"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_94"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_95"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_96"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_97"></a>
                            <img data-src="img/plans/L-6_S-2.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="7"
                            data-floors="2-4"
                            data-section="1"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_98"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_99"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_100"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_101"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_102"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_103"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_104"></a>
                            <img data-src="img/plans/L-7_S-1_E-2_4.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="7"
                            data-floors="5-12"
                            data-section="1"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_105"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_106"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_107"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_108"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_109"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_110"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_111"></a>
                            <img data-src="img/plans/L-7_S-1_E-5_12.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="7"
                            data-floors="2-4"
                            data-section="2"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_112"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_113"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_114"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_115"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_116"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_117"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_118"></a>
                            <img data-src="img/plans/L-7_S-2_E-2_4.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="7"
                            data-floors="5-12"
                            data-section="2"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_119"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_120"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_121"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_122"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_123"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_124"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_125"></a>
                            <img data-src="img/plans/L-7_S-2_E-5_12.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="7"
                            data-section="3"
                            data-floors="2-12"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_126"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_127"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_128"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_129"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_130"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_131"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_132"></a>
                            <img data-src="img/plans/L-7_S-3.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="7"
                            data-section="4"
                            data-floors="2-12"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_133"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_134"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_135"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_136"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_137"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_138"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_139"></a>
                            <img data-src="img/plans/L-7_S-4.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="9"
                            data-floors="2-8"
                            data-section="1"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_140"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_141"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_142"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_143"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_144"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_145"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_146"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_147"></a>
                            <img data-src="img/plans/L-9_S-1.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="9"
                            data-floors="2-8"
                            data-section="2"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_148"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_149"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_150"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_151"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_152"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_153"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_154"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_155"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_156"></a>
                            <img data-src="img/plans/L-9_S-2.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="9"
                            data-floors="2-8"
                            data-section="3"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_157"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_158"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_159"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_160"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_161"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_162"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_163"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_164"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_165"></a>
                            <img data-src="img/plans/L-9_S-3.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="9"
                            data-floors="2-8"
                            data-section="4"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_166"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_167"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_168"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_169"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_170"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_171"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_172"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_173"></a>
                            <img data-src="img/plans/L-9_S-4.jpg" />
                        </div>

                        <div
                            className="pu_floor_img"
                            data-liter="10"
                            data-section="1"
                            data-floors="2-12"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_174"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_175"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_176"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_177"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_178"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_179"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_180"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_181"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_182"></a>
                            <img data-src="img/plans/L-10_S-1.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="10"
                            data-section="2"
                            data-floors="2-12"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_183"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_184"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_185"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_186"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_187"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_188"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_189"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_190"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_191"></a>
                            <img data-src="img/plans/L-10_S-2.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="10"
                            data-section="3"
                            data-floors="2-4"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_192"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_193"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_194"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_195"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_196"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_197"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_198"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_199"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_200"></a>
                            <img data-src="img/plans/L-10_S-3_E-2_4.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="10"
                            data-section="3"
                            data-floors="5-12"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_201"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_202"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_203"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_204"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_205"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_206"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_207"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_208"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_209"></a>
                            <img data-src="img/plans/L-10_S-3_E-5_12.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="10"
                            data-section="4"
                            data-floors="2-4"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_210"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_211"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_212"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_213"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_214"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_215"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_216"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_217"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_218"></a>
                            <img data-src="img/plans/L-10_S-4_E-2_4.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="10"
                            data-section="4"
                            data-floors="5-12"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_219"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_220"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_221"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_222"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_223"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_224"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_225"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_226"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_227"></a>
                            <img data-src="img/plans/L-10_S-4_E-5_12.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="12"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_228"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_229"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_230"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_231"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_232"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_233"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_234"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_235"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_236"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_237"></a>
                            <img data-src="img/plans/L-12_S-1.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="12"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_238"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_239"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_240"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_241"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_242"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_243"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_244"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_245"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_246"></a>
                            <img data-src="img/plans/L-12_S-2.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="12"
                            data-section="3"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_247"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_248"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_249"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_250"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_251"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_252"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_253"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_254"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_255"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_256"></a>
                            <img data-src="img/plans/L-12_S-3.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="13"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_257"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_258"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_259"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_260"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_261"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_262"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_263"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_264"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_265"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_266"></a>
                            <img data-src="img/plans/L-13_S-1.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="13"
                            data-section="2"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_267"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_268"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_269"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_270"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_271"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_272"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_273"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_274"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_275"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_276"></a>
                            <img data-src="img/plans/L-13_S-2.jpg" />
                        </div>
                        <div
                            className="pu_floor_img"
                            data-liter="14"
                            data-section="1"
                            data-floors="2-8"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_277"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_278"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_279"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_280"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_281"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_282"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_283"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_284"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_285"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_286"></a>
                            <img data-src="img/plans/L-14_S-1.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="1"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_287"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_288"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_289"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_290"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_291"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_292"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_293"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_294"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_295"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_296"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_297"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_298"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_299"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_300"></a>
                            <img data-src="img/plans/L-16_S-1_f-2-7.jpg" src="img/plans/L-16_S-1_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="1"
                            data-floors="8-19"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_301"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_302"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_303"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_304"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_305"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_306"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_307"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_308"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_309"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_310"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_311"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_312"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_313"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_314"></a>
                            <img data-src="img/plans/L-16_S-1_f-8-19.jpg" src="img/plans/L-16_S-1_f-8-19.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="2"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_315"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_316"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_317"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_318"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_319"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_320"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_321"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_322"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_323"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_324"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_325"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_326"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_327"></a>
                            <img data-src="img/plans/L-16_S-2_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="2"
                            data-floors="8-18"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_328"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_329"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_330"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_331"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_332"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_333"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_334"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_335"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_336"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_337"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_338"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_339"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_340"></a>
                            <img data-src="img/plans/L-16_S-2_f-8-18.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="3"
                            data-floors="2-10"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_341"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_342"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_343"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_344"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_345"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_346"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_347"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_348"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_349"></a>
                            <img data-src="img/plans/L-16_S-3,4,5_f-2-9_2-10.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="4"
                            data-floors="2-10"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_350"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_351"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_352"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_353"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_354"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_355"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_356"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_357"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_358"></a>
                            <img data-src="img/plans/L-16_S-3,4,5_f-2-9_2-10.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_16"
                            data-liter="16"
                            data-section="5"
                            data-floors="2-9"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_359"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_360"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_361"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_362"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_363"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_364"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_365"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_366"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_367"></a>
                            <img data-src="img/plans/L-16_S-3,4,5_f-2-9_2-10.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="1"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_368"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_369"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_370"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_371"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_372"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_373"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_374"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_375"></a>
                            <img data-src="img/plans/L-15_S-1_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="1"
                            data-floors="8-17"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_376"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_377"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_378"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_379"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_380"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_381"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_382"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_383"></a>
                            <img data-src="img/plans/L-15_S-1_f-8-17.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="2"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_384"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_385"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_386"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_387"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_388"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_389"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_390"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_391"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_392"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_393"></a>
                            <img data-src="img/plans/L-15_S-2_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="2"
                            data-floors="8-17"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_394"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_395"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_396"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_397"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_398"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_399"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_400"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_401"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_402"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_403"></a>
                            <img data-src="img/plans/L-15_S-2_f-8-17.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="3"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_404"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_405"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_406"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_407"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_408"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_409"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_410"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_411"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_412"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_413"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_414"></a>
                            <img data-src="img/plans/L-15_S-3_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="3"
                            data-floors="8-17"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_415"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_416"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_417"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_418"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_419"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_420"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_421"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_422"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_423"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_424"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_425"></a>
                            <img data-src="img/plans/L-15_S-3_f-8-17.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="4"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_426"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_427"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_428"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_429"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_430"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_431"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_432"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_433"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_434"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_435"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_436"></a>
                            <img data-src="img/plans/L-15_S-4_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="4"
                            data-floors="8-18"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_437"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_438"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_439"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_440"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_441"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_442"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_443"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_444"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_445"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_446"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_447"></a>
                            <img data-src="img/plans/L-15_S-4_f-8-18.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="5"
                            data-floors="2-7"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_448"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_449"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_450"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_451"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_452"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_453"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_454"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_455"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_456"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_457"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_458"></a>
                            <img data-src="img/plans/L-15_S-5_f-2-7.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_15"
                            data-liter="15"
                            data-section="5"
                            data-floors="8-19"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_459"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_460"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_461"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_462"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_463"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_464"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_465"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_466"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_467"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_468"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_469"></a>
                            <img data-src="img/plans/L-15_S-5_f-8-19.jpg" />
                        </div>

                        <div
                            className="pu_floor_img pu_floor_17"
                            data-liter="17"
                            data-section="1"
                            data-floors="2-5"
                            style={{ display: 'none' }}
                        >
                            <a href="" onClick={floorClick} className="floor_img_flat fl_1701"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_1702"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_1703"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_1704"></a>
                            <a href="" onClick={floorClick} className="floor_img_flat fl_1705"></a>
                            <img data-src="img/plans/L-17.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
