import Slider from '@material-ui/core/Slider';
import { React, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

export const GetFlat = () => {
    const [floor, setFloor] = useState([3, 17]);
    const [form, setForm] = useState({
        name: '',
        email: ''
    });
    const handleChangeFloor = (event, newValue_) => {
        setFloor(newValue_);
        let form = event.target.closest('form');
        form.querySelector('.slider-floor1').value = 'c ' + newValue_[0] + ' по ' + newValue_[1] + ' этажи';
    };

    const roomsClick = (e) => {
        document.querySelectorAll('.gf_rooms span').forEach((el) => {
            el.classList.remove('act');
        });
        e.target.classList.add('act');
        let form = e.target.closest('form');
        form.querySelector('.floors1').value = e.target.innerHTML;
    };

    const onInputChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const getUtms = (paths) => {
        let utm = {};
        if (paths.split('&').length > 0) {
            let params = paths.split('&');
            params.forEach((param) => {
                param = param.split('=');
                if (
                    param[0] == 'utm_medium' ||
                    param[0] == 'utm_content' ||
                    param[0] == 'utm_campaign' ||
                    param[0] == 'utm_term' ||
                    param[0] == 'utm_source'
                ) {
                    utm = { ...utm, [param[0]]: param[1] };
                }
            });
        }
        return utm;
    };

    const addError = (element) => {
        element.parentElement.parentElement.querySelector('label').classList.add('Mui-error');
        element.parentElement.parentElement.querySelector('input').parentElement.classList.add('Mui-error');
    };
    const checkPhone = (element) => {
        if (element.value.indexOf('_') != -1 || element.value.length == 0) {
            addError(element);
            return false;
        }
        return true;
    };

    const checkEmail = (element) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(element.value).toLowerCase())) {
            addError(element);
        } else {
            return true;
        }
    };
    const showAlert = (data, celtype) => {
        gtag('event', 'send', {
            event_category: 'forms',
            event_label: celtype,
            value: 1
        });
        gtag('event', 'send', {
            event_category: 'forms',
            event_label: 'vse',
            value: 1
        });
        ym(40310715, 'reachGoal', celtype);
        ym(40310715, 'reachGoal', 'vse');
        fbq('trackCustom', 'vse');
        VK.Goal('lead');
        document.querySelector('.popup_rgba').style.display = 'block';
        document.querySelectorAll('.popup_main').forEach((elem) => {
            elem.style.display = 'none';
        });
        document.querySelector('body').classList.add('overflow');
        document.querySelector('.pu_thx').style.display = 'block';
        fetch('lrcnt_react.php', {})
            .then((data) => data.ok && data.json())
            .then((response) => {
                data = { ...data, ...response };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };
                fetch('fd_log/main.php', requestOptions).then((data) => data.ok);
            });
    };

    const sendForm = (e) => {
        e.preventDefault();
        let flag = true;
        let form = e.target.closest('form');
        let celtype = e.currentTarget.getAttribute('celtype');
        let name = form.querySelector('input[name="name"]').value;
        let phone = form.querySelector('input[name="phone"]').value;
        let email = form.querySelector('input[name="email"]').value;
        let url = window.location.toString().split('?');
        let utm = null;
        if (url.length > 1) {
            utm = getUtms(url[1]);
        }

        if (!checkPhone(form.querySelector('input[name="phone"]'))) {
            flag = false;
        }
        if (
            form.querySelector('input[name="email"]') != null &&
            form.querySelector('input[name="email"]').value != ''
        ) {
            if (!checkEmail(form.querySelector('input[name="email"]'))) {
                flag = false;
            }
        }
        let text = form.querySelector('input.text').value;
        form.querySelectorAll('.dop-info').forEach((el) => {
            if (el.value.length != 0 && el.getAttribute('date') != null)
                text += ';' + el.getAttribute('date') + ':' + el.value;
        });

        if (flag) {
            let ClientID;
            ym(40310715, 'getClientID', function (clientID) {
                ClientID = clientID;
            });
            let senddata = {
                getCall: 'Y',
                celtype: celtype,
                name: name,
                phone: phone,
                ClientID: ClientID,
                text: text,
                ...utm
            };
            if (email != '') {
                senddata.email = email;
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(senddata)
            };

            fetch('fd_log/ajax.php', requestOptions);
            // .then(data => data.ok)
            // .then(response => {
            // });
            showAlert(senddata, celtype);
        }
    };

    return (
        <section className="get_flat">
            <div className="tm">
                <div className="tm_in">
                    <span>
                        Получите подборку свободных <br /> квартир с планировками и ценами
                    </span>
                </div>
            </div>
            <div className="gf_form_wrap">
                {/* <div className="def_ds gf_ds gf_ds_mob dec_square">
					<div className="ds_anim">
						<span>Высылаем<br /> в течение</span>
						<b>30</b>
						<p>минут</p>
					</div>
				</div> */}
                <form action="" className="gf_form form_main react_input_style">
                    <div className="floor_slid_wrap">
                        <div className="ga_name">Этаж</div>
                        <input type="text" name="" value={'с ' + floor[0]} className="floor_min" />
                        <input type="text" name="" value={'по ' + floor[1]} className="floor_max" />
                        {/*<div className="floor_slid slid_style ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"><div className="ui-slider-range ui-widget-header ui-corner-all" style={{left: "7.14286%", width: "78.5714%"}}></div><span className="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style={{left: "7.14286%"}}></span><span className="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style={{left: "85.7143%"}}></span></div>*/}
                        <Slider
                            className="slid_style_react"
                            defaultValue={floor}
                            aria-labelledby="range-slider2"
                            step={1}
                            min={2}
                            max={19}
                            onChange={handleChangeFloor}
                        />
                    </div>
                    <div className="gf_rooms">
                        <div className="ga_name">Комнаты</div>
                        <span onClick={roomsClick} className="act" data-klass="0">
                            Студии
                        </span>
                        <span onClick={roomsClick} data-klass="1">
                            1
                        </span>
                        <span onClick={roomsClick} data-klass="2">
                            2
                        </span>
                        <span onClick={roomsClick} data-klass="3">
                            3
                        </span>
                        <span onClick={roomsClick} data-klass="all">
                            Все
                        </span>
                    </div>
                    <TextField
                        name="name"
                        onChange={onInputChange}
                        label="Имя"
                        value={form.name}
                        style={{ width: '100%' }}
                    />
                    <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                        <TextField type="tel" name="phone" label="Ваш телефон" style={{ width: '100%' }} />
                    </InputMask>
                    <TextField
                        name="email"
                        onChange={onInputChange}
                        label="Ваш e-mail"
                        value={form.email}
                        style={{ width: '100%' }}
                    />
                    <input type="hidden" className="dop-info slider-floor1" date="Этаж" value="с 3 по 17 этажи" />
                    <input type="hidden" className="dop-info otdelka" date="Отделка" value="" />
                    <input type="hidden" className="dop-info floors1" date="Кол-во комнат" value="Студии" />
                    <input type="hidden" className="text" value="Получить подборку" />
                    <p style={{ marginTop: 2.04 + 'vw', fontSize: '14px' }}>
                        Отправляя форму, я даю согласие на{' '}
                        <a href="/policy.pdf" target="_blank">
                            обработку персональных данных
                        </a>
                    </p>
                    <button className="btn_main lead-btn" onClick={sendForm} celtype="getPodbor">
                        <span>Получить подборку квартир</span>
                    </button>
                </form>
                {/* <div className="def_ds gf_ds dec_square">
					<div className="ds_anim">
						<span>Высылаем<br /> в течение</span>
						<b>30</b>
						<p>минут</p>
					</div>
				</div> */}
            </div>
            <div className="clr"></div>
        </section>
    );
};
