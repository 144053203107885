export const Details = () => {
    return(
        <section class="details">
			<div class="tm">
				<div class="tm_in">
					<span>Все продумано<br /> до мелочей</span>
					<p>Уютные квартиры в «Светлограде»<br /> сдаются с предчистовой отделкой и<br /> полностью готовы для косметического <br />ремонта</p>
				</div>
			</div>
			<div class="details_wrap">
				<div class="detail_sect det_communicat"><div class="det_bg"></div><div class="det_ico"></div><span>Центральные<br /> коммуникации</span></div>
				<div class="detail_sect det_system"><div class="det_bg"></div><div class="det_ico"></div><span>Надежная отопительная<br /> система</span></div>
				<div class="detail_sect det_lift"><div class="det_bg"></div><div class="det_ico"></div><span>Грузопассажирские<br /> лифты — по 2 на подъезд</span></div>
				<div class="detail_sect det_rostel"><div class="det_bg"></div><div class="det_ico"></div><span>Интернет от<br /> «Ростелекома»</span></div>

				<div class="detail_sect det_designer"><div class="det_bg"></div><div class="det_ico"></div><span>Дизайнерские мопы</span></div>
				<div class="detail_sect det_fire"><div class="det_bg"></div><div class="det_ico"></div><span>Система пожаротушения</span></div>
				<div class="detail_sect det_metall"><div class="det_bg"></div><div class="det_ico"></div><span>Металлическая входная<br /> дверь</span></div>
				<div class="detail_sect det_balcon"><div class="det_bg"></div><div class="det_ico"></div><span>Просторные <br /> застекленные балконы. <br /> Прочные и Экологичные <br /> окна</span></div>

				<div class="detail_sect det_ekolog"><div class="det_bg"></div><div class="det_ico"></div><span>Звукоизоляция пола —<br /> экологичный «Пенолон»</span></div>
				<div class="detail_sect det_water"><div class="det_bg"></div><div class="det_ico"></div><span>Приборы учета воды и<br /> тепла</span></div>
				<div class="detail_sect det_socket "><div class="det_bg"></div><div class="det_ico"></div><span>Выключатели и розетки</span></div>
				<div class="detail_sect det_elect"><div class="det_bg"></div><div class="det_ico"></div><span>Разводка электрики</span></div>
			</div>
		</section>
    )
}