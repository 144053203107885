import {React, useState} from "react"
import Slider from "react-slick"
import "./css/slick.min.css"
import "./css/slick-theme.min.css"
import { LightgalleryProvider, LightgalleryItem, useLightgallery} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

export const Gallery = () => {
    const [active, setActive] = useState("yard")
    const slides = {
        /*postfix: ".jpg",
            postfix_b: "b.jpg", */
        "yard": {
            prefix:"img/gallery/kd_sect/kds_img_",
            count: 24,
            optional:[
                {
                    small:"img/gallery/kd_sect/kds_img_25.jpg",
                    big:"https://youtu.be/QVv12Sux6E8"
                }
            ]
        },
        "alley": {
            prefix:"img/gallery/alley_sect/alley_img_",
            count:12,
        },
        "entries": {
            prefix:"img/gallery/entries_sect/entries_img_",
            count: 8,
        },
        "entrance": {
            prefix:"img/gallery/entrance_sect/entrance_img_",
            count: 13,
        },
        "withoutVehicles": {
            prefix:"img/gallery/without_vehicles/without_vehicles_img_",
            count: 1,
        },
        "video": {
            optional:[
                {
                    small: "img/gallery/gall_video/vidg_img_1.jpg",
                    big: "https://youtu.be/QVv12Sux6E8"
                },
                {
                    small: "img/gallery/gall_video/vidg_img_2.jpg",
                    big: "https://youtu.be/3w3kLbfivNs"
                },
                {
                    small: "img/gallery/gall_video/vidg_img_3.jpg",
                    big: "https://youtu.be/k1GBhpm4Fg0"
                },
                {
                    small: "img/gallery/gall_video/vidg_img_4.jpg",
                    big: "https://youtu.be/dVZkLzmhBGM"
                },
            ]
        },
        "reviews": {
            optional:[
                {
                    small:  "img/gallery/gall_video/reviewg_img_1.jpg",
                    big: "https://youtu.be/lzboH-kuPMA"
                }
            ]
        }
    }
    //<div><a href="img/gallery/kd_sect/kds_img_24.jpg" rel="yard" class="gal_img fb"><img src="img/gallery/kd_sect/kds_img_24.jpg"></a></div>
    const navClick = (event) => {
        event.preventDefault()
        document.querySelectorAll('.gal_nav li').forEach((element)=> {
            element.classList.remove('act')
        })
        event.currentTarget.classList.add('act')
        setActive(event.currentTarget.querySelector('a').getAttribute('data'))
    }
    let images = []
    for(let i=1; i<=slides[active].count; i++) {
        images.push({
            thumb: slides[active].prefix + i + ".jpg",
            image: slides[active].prefix + i + "_big.jpg",
        })
    }
    if (slides[active].optional){
        slides[active].optional.map((el)=>
        images.push({
            thumb: el.small,
            image: el.big,
        })
        )
       
    }
    
    const PhotoLightItem = ({ image, thumb, group }) => (
        <LightgalleryItem group={group} src={image} thumb={thumb.indexOf('.jpg')?thumb:image} itemClassName={image.indexOf('.jpg')!==-1?"gal_img":"gal_img gs_vid"}>
          <img src={thumb.indexOf('.jpg')?thumb:image} style={{ width: "100%", maxHeight:"409px"}} />
        </LightgalleryItem>
    );
    const slides_html = images.map((image)=> <div>
        <PhotoLightItem key={image.image} thumb={image.thumb} image={image.image} group="group"  />
    </div>)
    
    
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
          }
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
          }
        }
        ]
    };
    return(
        <section className="gallery">
            <div className="tm">
                <div className="tm_in">
                    <span>Галерея</span>
                </div>
                <ul className="gal_nav">
                    <li onClick={navClick} className="act"><a href="" data="yard"><span>Детские и спортивные площадки</span></a></li>
                    <li onClick={navClick}><a href="" data="alley"><span>Аллея и Бульвар</span></a></li>
                    <li onClick={navClick}><a href="" data="entries"><span>Входные группы</span></a></li>
                    <li onClick={navClick}><a href="" data="entrance"><span>Дизайнерские подъезды</span></a></li>
                    <li onClick={navClick}><a href="" data="withoutVehicles"><span>Двор без машин</span></a></li>
                    <li onClick={navClick}><a href="" data="video"><span>Видео</span></a></li>
                    <li onClick={navClick}><a href="" data="reviews"><span>Отзывы</span></a></li>
                </ul>
            </div>
            <LightgalleryProvider style={{display:"none"}}
               
                >
                <Slider className="gal_slid" {...settings}>
                    {slides_html}
                </Slider>
            </LightgalleryProvider>
            <div className="div_tit">А ещё в "Светлограде"</div>
        </section>
    )
}