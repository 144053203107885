import logo from './logo.svg';
import './App.css';
//import "./css/style_1102.css";
import './css/fonts.css';
import './css/style.css';
import { Menu } from './components/menu';
import { BlocksContext } from './context/blocksContext';
import { useBlocks } from './hooks/blocks.hook';
import { Cookie } from './components/cookie';

import { Loader } from './components/loader';

function App() {
    const { blocks, setBlocks, popup, setPopup, menuClick, setMenuClick } = useBlocks();
    return (
        <div className="App">
            <BlocksContext.Provider
                value={{
                    blocks,
                    setBlocks,
                    popup,
                    setPopup,
                    menuClick,
                    setMenuClick
                }}
            >
                <Loader />
                <Cookie />
            </BlocksContext.Provider>
        </div>
    );
}

export default App;
