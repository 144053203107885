export const Developer = () => {
    return (
        <section class="developer" id="ankor7">
            <div class="dev_wrap">
                <div class="dev_sect">
                    <div class="dev_tit">
                        <img src="img/rebrending/logo.svg" />
                        <span>
                            Надежный
                            <br /> застройщик
                        </span>
                        <p>
                            Наши дома - качественное жилье
                            <br />
                            с развитой инфраструктурой, которое
                            <br />
                            может позволить себе каждый
                        </p>
                    </div>
                    <div class="def_ds dec_square">
                        <div class="ds_anim">
                            <span>Сдано</span>
                            <b>36</b>
                            <p>Домов</p>
                        </div>
                    </div>
                </div>
                <div class="dev_sect">
                    <div class="dev_info">
                        <div class="dev_sub">«Семья» единомышленников</div>
                        <p>
                            Строительная компания «Семья» — содружество людей, объединенных общей целью: строить
                            доступное и качественное жилье. Мы делаем все, чтобы «держать» цены на одном уровне и
                            стараемся предоставлять самые удобные условия покупки
                        </p>
                    </div>
                </div>
                <div class="dev_sect">
                    <div class="dev_doc">
                        <p>
                            Наша миссия — комплексное освоение территорий, создание полноценной городской инфраструктуры
                            в границах жилого комплекса
                        </p>
                        <div class="dev_red_text">
                            25 литеров сданы точно в срок
                            <br /> 11 литеров сданы ранее срока{' '}
                        </div>
                        <p>
                            Строительная компания «Семья» входит в ТОП-10 застройщиков Краснодарского края и ТОП-100
                            лучших застройщиков России. Нашей отличительной особенностью является строительство жилых
                            комплексов с готовой инфраструктурой в шаговой доступности: школами, садами, магазинами,
                            фитнес-залами. За 10 лет работы все наши дома были сданы в срок, либо даже ранее срока.
                            Выбирая нас, Вы можете быть уверены в качестве строительства и правильности Вашего выбора.{' '}
                        </p>
                        <div class="dev_doc_desk">
                            <span>
                                Со всей документацией по объекту вы можете ознакомиться на официальном сайте Единой
                                информационной системы жилищного строительства{' '}
                                <a
                                    href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%B5%D0%B4%D0%B8%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80-%D0%B7%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%89%D0%B8%D0%BA%D0%BE%D0%B2/%D0%B7%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%89%D0%B8%D0%BA/934"
                                    target="_blank"
                                >
                                    наш.дом.рф{' '}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="dev_sect">
                    <img src="img/dev_img.jpg" />
                    <div class="dev_doc dev_doc_mob">
                        <span>
                            Со всей документацией по объекту вы можете ознакомиться на официальном сайте Единой
                            информационной системы жилищного строительства{' '}
                            <a
                                href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%B5%D0%B4%D0%B8%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80-%D0%B7%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%89%D0%B8%D0%BA%D0%BE%D0%B2/%D0%B7%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%89%D0%B8%D0%BA/934"
                                target="_blank"
                            >
                                наш.дом.рф{' '}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
};
