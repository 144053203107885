import {React} from "react"

export const Parking = () => {

    return (
        <section className="parking_avenue" id="ankor3">
			<div className="pa_side">
				<div className="pa_tit"><span>Зеленая аллея с <br /> водоемом более <br /> <l>1 км</l> во дворе</span></div>
				<img src="img/pa_img001.jpg" />
			</div>
			<div className="pa_side">
				<div className="pa_tit"><span><l>6000</l> бесплатных <br /> парковочных <br /> мест</span></div>
				<img src="img/pa_img002.jpg" />
			</div>
		</section>
    )
}