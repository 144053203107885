import React, { useEffect, useState } from 'react'
import { LightgalleryProvider, LightgalleryItem, useLightgallery} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

export const Childhood = () => {
    const GROUP = [
       "img/kids_gard_gall/kg_img_1.jpg",
       "img/kids_gard_gall/kg_img_2.jpg",
       "img/kids_gard_gall/kg_img_3.jpg",
       "img/kids_gard_gall/kg_img_4.jpg",
       "img/kids_gard_gall/kg_img_5.jpg",
       "img/kids_gard_gall/kg_img_6.jpg",
       "img/kids_gard_gall/kg_img_7.jpg",
       "img/kids_gard_gall/kg_img_8.jpg",
       "img/kids_gard_gall/kg_img_9.jpg",
       "img/kids_gard_gall/kg_img_10.jpg",
       "img/kids_gard_gall/kg_img_11.jpg",
       "img/kids_gard_gall/kg_img_12.jpg",
       "img/kids_gard_gall/kg_img_13.jpg",
       "img/kids_gard_gall/kg_img_14.jpg",
       "img/kids_gard_gall/kg_img_15.jpg",
       "img/kids_gard_gall/kg_img_16.jpg",
       "img/kids_gard_gall/kg_img_17.jpg",
       "img/kids_gard_gall/kg_img_18.jpg",
    ]
    const PhotoItem = ({ image, thumb, group }) => (
      <div style={{  padding: "5px" }}>
        <LightgalleryItem group={group} src={image} thumb={thumb}>
          <img data-src={image} style={{ width: "100%" }} />
        </LightgalleryItem>
      </div>
    );

    const OpenButtonWithHook = props => {
      const { openGallery } = useLightgallery();
      return (
        <a href="" onClick={(e) => {e.preventDefault();openGallery("group")}} class="def_ds dec_square">
          <span class="ds_anim">
            <span>Посмотрите</span>
            <b>18</b>
            <p> фото</p>
          </span>
        </a>	
      );
    };
    
    return (
        <section class="kids_gard scale_sect">
          <LightgalleryProvider style={{display:"none"}}>
              <div class="tm kd_tm" style={{top: "0px"}}>
                <div class="tm_in">
                  <span>Детский сад №204 во <br /> дворе</span>
                  <p>5 минут пешком от самого <br /> отдаленного дома</p>
                  <OpenButtonWithHook />
                </div>
              </div>
              <img src="img/scroll_ico.png" alt="" class="scroll_ico" />
                 <div
                  style={{
                    display: "none",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                {GROUP.map((p) => <PhotoItem key={p} image={p} group="group" />)}
              </div>
                
          </LightgalleryProvider>
             
		    </section>
    )
}