import {React} from "react"

export const LocMap = () => {
    

    return(
        <section class="loc_map">
			<div class="lm_side">
				<div class="lm_tit">Расположение<br /> домов на карте</div>
				<img src="img/scroll_ico_dark.png" alt="" class="scroll_ico" />				
				<div class="def_ds svg_ds svg_ds_mob dec_square">
					<div class="ds_anim">
						<span>До моря</span>
						<b>120</b>
						<p>минут</p>
					</div>
				</div>
				<div class="def_ds lm_ds dec_square">
					<div class="ds_anim">
						<span>До центра</span>
						<b>15</b>
						<p>минут</p>
					</div>
				</div>
			</div>
			<div class="lm_side">
				<div class="lm_info">
					<span>ЖК «Светлоград» — это 37.7 гектаров<br /> комплексной жилой застройки</span>
					<p>Здесь есть все для комфортной жизни.</p>
				</div>
			</div>
		</section>
    )
}