import {React, useEffect, useState} from "react"
import Slider from "react-slick"
import { LightgalleryProvider, LightgalleryItem, useLightgallery} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

var loading = false
export const HodStr = () => {
    const [hod_a, setHod_a] = useState(null);
    const [active, setActive] = useState({
        year:null,
        month: null,
        photos:[]
    })
    const [photos, setPhotos] = useState([])
    const headers = { 'Content-Type': 'application/json' }
    useEffect(()=> {
		if (!loading){
			loading=true
			fetch("https://этосветлоград.рф/hod_react.php", headers)
			.then(res => res.json())
			.then((result)=>{
                setHod_a(result)
                let year = Object.keys(result.struct)[Object.keys(result.struct).length-1]
                let month =  Object.keys(result.struct[year])[Object.keys(result.struct[year]).length-1]
                let photos = result.photos[year+"_"+month]
                setActive({
                    year:year,
                    month: month,
                    photos:photos
                })
                setPhotos(photos)
			})
            setTimeout(()=>{
                if (document.querySelector('.hod_str .slick-next')) {
                    document.querySelector('.hod_str .slick-next').click()
                }
            }, 1000)
            
		}	
    },[])
    let months


    const yearClick = (e)=>{
        e.preventDefault()
        let tmp = active
        tmp.year = e.target.getAttribute('data')
        tmp.month =  Object.keys(hod_a.struct[tmp.year])[Object.keys(hod_a.struct[tmp.year]).length-1]
        tmp.photos = hod_a.photos[tmp.year+"_"+tmp.month]
        
        setActive(tmp)
        setPhotos(tmp.photos)
    }
    const monthClick = (e)=>{
        e.preventDefault()
        let tmp = active
        tmp.month = e.target.getAttribute('data')
        tmp.photos = hod_a.photos[tmp.year+"_"+tmp.month]
        setActive(tmp)
        setPhotos(tmp.photos)
    }
    const PhotoLightItem = ({ image, group }) => (
        <LightgalleryItem group={group} src={image} thumb={image}>
          <img class="" src={image} />
        </LightgalleryItem>
    );
    const monthName = (month)=>{
        let name=''
        switch(month) {
            case '1':
                name= "Январь"
                break
            case '2':
                name= "Февраль"
                break
            case '3':
                name= "Март"
                break
            case '4':
                name= "Апрель"
                break
            case '5':
                name= "Май"
                break
            case '6':
                name= "Июнь"
                break
            case '7':
                name= "Июль"
                break
            case '8':
                name= "Август"
                break
            case '9':
                name= "Сентябрь"
                break
            case '10':
                name= "Октябрь"
                break
            case '11':
                name= "Ноябрь"
                break
            case '12':
                name= "Декабрь"
                break
        }
        return name
    }

    let years
    let photos_html
    if (hod_a!=null && active.year!=null && active.month!=null && active.photos.length>0){
        years = Object.keys(hod_a.struct).reverse().map((year)=>{
            return <a href="#" onClick={yearClick} class={year==active.year?"act":""} data={year}>{year}</a>
        })
        months = Object.keys(hod_a.struct[active.year]).reverse().map((month)=>{
            return <a href="" onClick={monthClick} data={month} class={month==active.month?"act":""}>{monthName(month)}</a>
        })
        photos_html = photos.map((photo)=> <div class="hds_in">
                <PhotoLightItem image={photo} group={active.year+"_"+active.month}/>
            </div>
        )
    }
    
    

   
    
    

    
    var settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: false,
        setFocus:true,
      responsive: [
        {
          breakpoint: 1190,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 550,
         
          settings: {
            slidesToShow: 1
          }
        }
        ]
    };

    
   

    return(
        <section class="hod_str" id="hodstr">
            <div class="tm">
				<div class="tm_in">
					<span>Ход строительства</span>
				</div>
			</div>
			<div class="hds_nav">
				<div class="hds_years">
                        {years}
                </div>
				<div class="hds_months">                    
                    <ul>
                        {months}
                    </ul>
                </div>
                <LightgalleryProvider style={{display:"none"}}>
                    <Slider className="hds_slid" {...settings}>
                        {photos_html}
                    </Slider>
                </LightgalleryProvider>
            </div>
    </section>
    )
}