import Cookies from 'js-cookie';

export const Cookie = () => {
    const showPopup = (e) => {
        e.preventDefault();
        let popup = e.target.getAttribute('data-popup');
        document.querySelector('.popup_rgba').style.display = 'block';
        document.querySelectorAll('.popup_rgba img').forEach((el) => {
            if (el.getAttribute('src') == null && el.getAttribute('data-src') != null) {
                el.setAttribute('src', el.getAttribute('data-src'));
            }
        });
        document.querySelectorAll('.popup_main').forEach((el) => (el.style.display = 'none'));

        document.querySelector('.popup_main.' + popup).style.display = 'block';
        document.querySelector('body').classList.add('overflow');
    };

    (function () {
        setTimeout(() => {
            const cookieCheck = Cookies.get('cookie_check');

            if (!cookieCheck) {
                document.querySelector('.cookie-popup').classList.add('show');

                const popup = document.querySelector('.cookie-popup');
                const close = popup.querySelector('.close');

                close.addEventListener('click', closeCookie);

                function closeCookie() {
                    document.querySelector('.cookie-popup').classList.remove('show');
                    Cookies.set('cookie_check', 1, { expires: 365 });
                }
            }
        }, 1000);
    })();

    return (
        <div class="cookie-popup">
            <p class="cookie-popup__text">
                <span>Мы используем файлы cookie.&nbsp;</span>
                <a class="link link--cookie" onClick={showPopup} data-popup="pu_ck" style={{ cursor: 'pointer' }}>
                    Подробнее
                </a>
            </p>
            <a class="button button--cookie close" style={{ cursor: 'pointer' }}>
                Соглашаюсь
            </a>
        </div>
    );
};
