import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';

export const Contacts = () => {
    const showPopup = (e) => {
        e.preventDefault();
        let popup = e.target.getAttribute('data-popup');
        document.querySelector('.popup_rgba').style.display = 'block';
        document.querySelectorAll('.popup_rgba img').forEach((el) => {
            if (el.getAttribute('src') == null && el.getAttribute('data-src') != null) {
                el.setAttribute('src', el.getAttribute('data-src'));
            }
        });
        document.querySelectorAll('.popup_main').forEach((el) => (el.style.display = 'none'));

        document.querySelector('.popup_main.' + popup).style.display = 'block';
        document.querySelector('body').classList.add('overflow');
    };
    return (
        <section class="contacts" id="contacts">
            <div class="cont_wrap">
                <div class="cont_left">
                    <div class="cont_tm">
                        <span>Контакты</span>
                    </div>
                    <div class="sales_depart">
                        <div class="sd_tit">Отдел продаж на объекте</div>
                        <div class="sd_loc">Краснодар, Красных Партизан, 1/3</div>
                        <div class="sd_phone roistat-phone">8 800 222 81 81</div>
                        <div class="sd_time">Пн-Пт с 9:00-19:00, Сб-Вс с 10:00-18:00</div>
                        <a class="sd_loc" href="/policy.pdf">
                            Политика конфиденциальности
                        </a>
                    </div>
                    <div class="sd_quest">
                        <p>
                            Остались вопросы?
                            <br /> Задайте их менеджеру!
                        </p>
                        <a href="#" class="quest_btn show_popup" onClick={showPopup} data-popup="pu_quest">
                            Задать вопрос
                        </a>
                    </div>
                </div>
                <div class="cont_right">
                    <div class="cont_map">
                        <YMaps>
                            <div>
                                <Map
                                    defaultState={{ center: [45.070358, 38.929966], zoom: 12, controls: [] }}
                                    style={{ width: '100%', height: '100%', position: 'absolute' }}
                                >
                                    <ZoomControl options={{ float: 'left' }} />
                                    <Placemark
                                        geometry={[45.055614, 38.956368]}
                                        options={{
                                            iconLayout: 'default#image',
                                            iconImageHref: 'img/rebrending/logo3.svg',
                                            iconImageSize: [60, 30],
                                            iconImageOffset: [-24, -50]
                                        }}
                                    />
                                    <Placemark
                                        geometry={[45.078467, 38.89565]}
                                        options={{
                                            iconLayout: 'default#image',
                                            iconImageHref: 'img/rebrending/logo3.svg',
                                            iconImageSize: [60, 30],
                                            iconImageOffset: [-24, -50]
                                        }}
                                    />
                                </Map>
                            </div>
                        </YMaps>
                    </div>
                    <div class="cont_family">
                        <div class="cfm_wrap">
                            <div class="cfm_tit">Офис ООО «Семья»</div>
                            <div class="cfm_loc">Краснодар, Воровского, 172</div>
                            <div class="cfm_phone roistat-phone">8 800 222 81 81</div>
                            <div class="cfm_time">Пн-Пт с 9:00-19:00, Сб-Вс с 10:00-18:00</div>
                        </div>
                    </div>
                    <div class="cont_mail">
                        <div class="cfm_wrap">
                            <img src="img/rebrending/logo.svg" />
                            <div class="develop_info">
                                <span>Создание сайта</span>
                                <a href="">
                                    <img src="img/develop_cont_ico.svg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
