import React, { useState, useRef, useEffect } from 'react'
import Slider from "react-slick"

export const Infra = () => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    //i dont seem to need this
    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, []);
   

    var settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false
    };
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: false,
        centerMode: true,
        centerPadding: '0px',
	      responsive: [
	        {
	          breakpoint: 640,
	          settings: {
	            slidesToShow: 3,
	            slidesToScroll: 1
	          }
	        },
	        {
	          breakpoint: 480,
	          settings: {
	            slidesToShow: 1,
	            slidesToScroll: 1
	          }
	        }
	        ]
    };

    let images = [
        "img/is_img/is_img01.jpg",
        "img/is_img/is_img02.jpg",
        "img/is_img/is_img03.jpg",
        "img/is_img/is_img04.jpg",
        "img/is_img/is_img05.jpg",
        "img/is_img/is_img06.jpg",
    ]
    


    const images_html = images.map((image)=> <div><div  className="is_img"><img src={image} /></div></div>)

    return (
        <section className="infra" id="infra" style={{maxWidth:"100%", overflow:"hidden", padding:0, margin:0}}>
			<div className="is_wrap">
				<div className="tm">
					<div className="tm_in">
						<span>И удобная<br /> инфраструктура<br /> района</span>
					</div>
				</div>
                <Slider className="infra_slid" 
                    {...settings}
                    asNavFor={nav2} ref={slider1}
                >
					{images_html}
				</Slider>
			</div>
            <Slider style={{width:"100%"}} className="infra_slid_nav" {...settings2} asNavFor={nav1}
        ref={slider2}
       
        swipeToSlide={true}
        focusOnSelect={true}>
				<div>
					<div className="isn_in is_avenue">
					<img src="img/is_act.svg" alt="" className="is_act" />
						<div className="is_ico"></div>
						<div className="is_name">Прогулочная аллея</div>
						<div className="is_info"></div>
					</div>
				</div>
				<div>
					<div className="isn_in is_sport">
					<img src="img/is_act.svg" alt="" className="is_act" />
						<div className="is_ico"></div>
						<div className="is_name">Спортивные<br /> площадки</div>
						<div className="is_info"></div>
					</div>
				</div>
				<div>
					<div className="isn_in is_kd">
					<img src="img/is_act.svg" alt="" className="is_act" />
						<div className="is_ico"></div>
						<div className="is_name">Детские площадки</div>
						<div className="is_info"></div>
					</div>
				</div>
				<div>
					<div className="isn_in is_hypermarket">
					<img src="img/is_act.svg" alt="" className="is_act" />
						<div className="is_ico"></div>
						<div className="is_name">Гипермаркеты</div>
						<div className="is_info">через дорогу «Лента», рядом «Магнит» и «Табрис»</div>
					</div>
				</div>
				<div>
					<div className="isn_in is_store">
					<img src="img/is_act.svg" alt="" className="is_act" />
						<div className="is_ico"></div>
						<div className="is_name">Магазины, салоны красоты, медцентр</div>
						<div className="is_info"></div>
					</div>
				</div>
				<div>
					<div className="isn_in is_transport">
					<img src="img/is_act.svg" alt="" className="is_act" />
						<div className="is_ico"></div>
						<div className="is_name">Общественный транспорт</div>
						<div className="is_info">остановка у комплекса</div>
					</div>
				</div>
			</Slider>
		</section>
    )
}