import React from "react"
import { LightgalleryProvider, LightgalleryItem, useLightgallery} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

export const School = () => {
        const GROUP = [
            "img/school_gall/sc_img_1.jpg",
            "img/school_gall/sc_img_2.jpg",
            "img/school_gall/sc_img_3.jpg",
            "img/school_gall/sc_img_4.jpg",
            "img/school_gall/sc_img_5.jpg",
            "img/school_gall/sc_img_6.jpg",
            "img/school_gall/sc_img_7.jpg",
            "img/school_gall/sc_img_8.jpg",
            "img/school_gall/sc_img_9.jpg",
            "img/school_gall/sc_img_10.jpg"
        ]
        const PhotoItem = ({ image, thumb, group }) => (
            <div style={{ padding: "5px", display:"none" }}>
              <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img src={image} style={{ width: "100%" }} />
              </LightgalleryItem>
            </div>
        );
        const OpenButtonWithHook = props => {
            const { openGallery } = useLightgallery();
            return (
              <a href="" onClick={(e) => {e.preventDefault();openGallery("group")}} class="def_ds sc_ds dec_square">
                <span class="ds_anim">
                    <span>Посмотрите</span>
                    <b>11</b>
                    <p> фото</p>
                </span>
            </a>		
            );
          };
        return(
            <section class="school_section scale_sect">
                {/*				<div class="back_bg"></div>
                    <div class="front_bg" id="school_front_bg"></div>*/}
                <LightgalleryProvider>
                    <div class="tm sc_tm">
                        <div class="tm_in">
                            <span>Современная школа №16 во дворе<br /> уже открыта.</span>
                            <p>Безопасно и быстро. <br /> Переходить дорогу не нужно.</p>	
                        </div>
                    </div>
                    <OpenButtonWithHook />
                    <img src="img/scroll_ico.png" alt="" class="scroll_ico" />
                    {GROUP.map((p) => <PhotoItem key={p} image={p} group="group" />)}
                </LightgalleryProvider>
            </section>
        )
}