import {React, useEffect, useState} from "react"
import Konva from "konva"
var stage
var layer
export const Plan = () => {
	const [hovered, setHovered] = useState(false)

	const paths = [
		{
			name: 'paths_plan1',
			liter: 6,
			condition: 1,
			path: 'M787.8,171.6 859.9,124.4 876.1,133.1 877,163.9 811.2,208.5 788.1,198.9 z',
			
			// sdacha: "2 кв. 2021",
			sdacha: "Сдан",
		},
		{
			name: 'paths_plan2',
			liter: 7,
			condition: 1,
			path: 'M621.7,278 621.7,265.4 771.1,167.2 787.3,174.6 787.8,217.6 666.6,304.7 664.1,303.3 643.5,318 632.1,311.8 630.7,282.5 z',
			sdacha: "Сдан"
		},
		{
			name: 'paths_plan3',
			liter: 9,
			condition: 1,
			path: 'M848.5,199.3 975.6,113 994.3,118.6 994.4,145.9 863.7,237.4 849.4,227.7 z',
			// sdacha: "2 кв. 2021",
			sdacha: "Сдан",
		},
		{
			name: 'paths_plan4',
			liter: 10,
			condition: 1,
			path: 'M683.9,297.3 832.9,195.2 848,205.3 849,246.7 706,350.2 687.9,339.4 z',
			sdacha: "Сдан",
	
		},
		{
			name: 'paths_plan5',
			liter: 12,
			condition: 1,
			path: 'M908.6,229.6 1007.8,160.8 1024.1,169.5 1023.3,200.1 931.2,270 908.6,258.8 z',
			// sdacha: "2 кв. 2021",
			sdacha: "Сдан",
		},
		{
			name: 'paths_plan6',
			liter: 13,
			condition: 1,
			path: 'M971.4,291.8 972.2,260.4 1041.3,209.7 1054.9,218.7 1055.2,251.5 990.5,299.3 z',
			// sdacha: "2 кв. 2021",
			sdacha: "Сдан",
		},
		{
			name: 'paths_plan7',
			liter: 14,
			condition: 1,
			path: 'M1036.1,324.2 1036.8,291.2 1070.2,266 1086.9,273.6 1087,306.3 1054.3,333 z',
			// sdacha: "2 кв. 2021",
			sdacha: "Сдан",
		},
		{
			name: 'paths_plan8',
			liter: 19,
			condition: 1,
			path: 'M1491,138 1494.7,110.3 1543.3,69.4 1559.1,76.5 1557.5,106.1 1508,146.4  z',
			sdacha: "1 кв. 2022",
		},
		{
			name: 'paths_plan9',
			liter: 20,
			condition: 1,
			path: 'M1586.3,115.5 1602.3,122.7 1599.8,148.5 1550.4,192.5 1532.1,184.5 1536.7,157  z',
			sdacha: "1 кв. 2022",
		},
		{
			name: 'paths_plan10',
			liter: 21,
			condition: 1,
			path: 'M1580.4,207.1 1630,162.8 1647.7,169.4 1645,199.3 1597.9,243.1 1576.5,234.6  z',
			sdacha: "1 кв. 2022",
		},
		{
			name: 'paths_plan11',
			liter: 22,
			condition: 1,
			path: 'M1621.1,288.2 1627.7,257.8 1676.8,212.7 1695.1,218.1 1691.8,244.5 1642.4,296.6  z',
			sdacha: "1 кв. 2022",
		},
		{
			name: 'paths_plan12',
			liter: 15,
			condition: 2,
			path: 'M405.4,833.3 607.1,770 608.3,766.4 675.8,744.6 675.8,741.7 738.3,722 747.5,740.5 751.4,808.9 754,810.6 754,817.2 428.6,927.4 418,898.4 z',
			sdacha: "2 кв. 2023",
		},
		{
			name: 'paths_plan13',
			liter: 16,
			condition: 2,
			path: 'M771.3,920.2l-4.5-75.2l0-0.1c0.2-0.5,0.3-0.7,0.3-0.7l-11.8-18.7l-10.8,4.7l-1-1.5l-2.1,0.7l-0.1-0.1 l-2.3,0.9l-2.7,0.9v0.2l-18.4,7.3v2l-6,2.5l-1-2.5l-6.1,2.3L704,846L690,851l-0.8,3.7l-11.2,4.2l-0.2-0.3l-0.2,0.1l-0.7-1.5 l-6.5,2.7v1.5l-0.5,0.2v0.1l-10.3,4.7l-0.2-0.3v-1.5l-6.7,2.2l-0.7,2.2l-5.3,2v-2l-6.8,2.7v1.2l-0.9,0.3v1.3l-14.9,6.1l-0.2,1.3 l-1.6,0.6l2.8,11.6l1.8,19l-31.2,11.8v-3l-5.7,3.2v2.5l-60.1,23.3l0,0l-3.5,1.4v-0.5v-2.3l-5.9,2.4v2.5l-32.1,13.3v2l-28.3,11.7 l-1.8-2.6l-6.7,2.4v3l-28.9,10.8l6.6,36.7l11.3,22.8l117-49.8l2.2,0.8l79.7-32.6l2.8,2l25.6-10.2l-0.1-1.6l40.3-17.4l3.1,1.6 l11.2-4.8l-0.1-2.6L771.3,920.2 z',
			sdacha: "2 кв. 2023",
		},
		{
			name: 'paths_plan17',
			liter: 17,
			condition: 1,
			path: '',
			sdacha: "1 кв. 2023",
		},
	]
	const mouseEnter = (e) => {
		setHovered(e.target.attrs.liter)
		const container = e.target.getStage().container();
    	container.style.cursor = "pointer"
	}
	const mouseLeave = (e) => {
		const container = e.target.getStage().container();
		container.style.cursor = "default"
		setHovered(false)
	}

	const contHover = (e) => {
		setHovered(parseInt(e.target.getAttribute('data-liter')))
	}
	const contLeave = (e) => {
		setHovered(false)
	}

	const contClick = (e) => {
		const liter = e.target.getAttribute('data-liter')
		if (liter!=null){
			document.querySelector('.popup_rgba').style.display="block"
			document.querySelectorAll('.popup_rgba img').forEach((el)=> {
				if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
					el.setAttribute('src', el.getAttribute('data-src'))
				}
			})
			document.querySelectorAll(".popup_main").forEach((el)=> el.style.display="none")
			document.querySelector(".popup_main.pu_floor").style.display="block"
			document.querySelectorAll(".popup_main.pu_floor .pu_floor_img").forEach((el)=> {
				el.style.display="none"
			})
			document.querySelector(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']").style.display="block"
			/* [data-section='1'] */
			
			document.querySelector("body").classList.add("overflow")
			document.querySelector(".popup_main.pu_floor .pu_tm i").innerHTML=liter
			let sdachca
			paths.forEach(path=> {
				if (path.liter == liter){
					sdachca = path.sdacha
				}
			})
			document.querySelector(".popup_main.pu_floor .renting_date").innerHTML=sdachca
			let sections = parseInt(document.querySelectorAll(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']")[document.querySelectorAll(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']").length-1].getAttribute('data-section'))
			let i = 0
			document.querySelectorAll('.pu_entrance span').forEach((el)=>{
				if (i<sections){
					el.style.display = "block"
				} else {
					el.style.display = "none"
				}
				if (i==0) el.classList.add('act') 
				else el.classList.remove('act')
				i++
			})
		}
	}

	
	
	useEffect(()=>{
		var stageWidth = 1920;
      	var stageHeight = 1124;
		stage = new Konva.Stage({
			container: 'paths_plan',
			width: stageWidth,
			height: stageHeight,
		});
		layer = new Konva.Layer();
		stage.add(layer)
		paths.map((el)=>{
			var path = new Konva.Path({
				opacity:0.7,
				data: el.path,
				fill:hovered==el.liter?el.condition==2?'green':'#FFA800':'transparent',
				scaleX: 1,
				scaleY: 1
			});
			path.on('mouseenter', function (e) {
				var fill = el.condition==2?'green':'#FFA800';
				this.fill(fill);
				layer.draw();
				setHovered(el.liter)
				const container = e.target.getStage().container();
    			container.style.cursor = "pointer"
			})
			path.on('mouseleave', function (e) {
				var fill = 'transparent';
				this.fill(fill);
				layer.draw();
				setHovered(false)
				const container = e.target.getStage().container();
    			container.style.cursor = "default"
			})
			path.on('click', function(){
				const liter = el.liter
				document.querySelector('.popup_rgba').style.display="block"
				document.querySelector(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"'] img").setAttribute('src', document.querySelector(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"'] img").getAttribute('data-src'))
				/*document.querySelectorAll('.popup_rgba img').forEach((el)=> {
					if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
						el.setAttribute('src', el.getAttribute('data-src'))
					}
				})*/
				document.querySelectorAll(".popup_main").forEach((el)=> el.style.display="none")
				document.querySelector(".popup_main.pu_floor").style.display="block"
				document.querySelectorAll(".popup_main.pu_floor .pu_floor_img").forEach((el)=> {
					el.style.display="none"
				})
				document.querySelector(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']").style.display="block"
				/* [data-section='1'] */
				document.querySelector("body").classList.add("overflow")
				document.querySelector(".popup_main.pu_floor .pu_tm i").innerHTML=liter
				document.querySelector(".popup_main.pu_floor .renting_date").innerHTML=el.sdacha
				
				let sections = parseInt(document.querySelectorAll(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']")[document.querySelectorAll(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']").length-1].getAttribute('data-section'))
				let i = 0
				document.querySelectorAll('.pu_entrance span').forEach((el)=>{
					if (i<sections){
						el.style.display = "block"
					} else {
						el.style.display = "none"
					}
					if (i==0) el.classList.add('act') 
					else el.classList.remove('act')
					i++
				})
			})
			path.on('touchstart', function(){
				const liter = el.liter
				document.querySelector('.popup_rgba').style.display="block"
				document.querySelectorAll('.popup_rgba img').forEach((el)=> {
					if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
						el.setAttribute('src', el.getAttribute('data-src'))
					}
				})
				document.querySelectorAll(".popup_main").forEach((el)=> el.style.display="none")
				document.querySelector(".popup_main.pu_floor").style.display="block"
				document.querySelectorAll(".popup_main.pu_floor .pu_floor_img").forEach((el)=> {
					el.style.display="none"
				})
				document.querySelector(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']").style.display="block"
				/* [data-section='1'] */
				document.querySelector("body").classList.add("overflow")
				document.querySelector(".popup_main.pu_floor .pu_tm i").innerHTML=liter
				document.querySelector(".popup_main.pu_floor .renting_date").innerHTML=el.sdacha
				
				let sections = parseInt(document.querySelectorAll(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']")[document.querySelectorAll(".popup_main.pu_floor .pu_floor_img[data-liter='"+liter+"']").length-1].getAttribute('data-section'))
				let i = 0
				document.querySelectorAll('.pu_entrance span').forEach((el)=>{
					if (i<sections){
						el.style.display = "block"
					} else {
						el.style.display = "none"
					}
					if (i==0) el.classList.add('act') 
					else el.classList.remove('act')
					i++
				})
			})
			layer.add(path)
		})
		layer.draw()

		function fitStageIntoParentContainer() {
			var container = document.querySelector('#paths_plan');
			var containerWidth = container.offsetWidth;
			var scale = containerWidth / stageWidth;
			stage.width(stageWidth * scale);
			stage.height(stageHeight * scale);
			stage.scale({ x: scale, y: scale });
			stage.draw();
		}
	
		fitStageIntoParentContainer();
		  // adapt the stage on any window resize
		window.addEventListener('resize', fitStageIntoParentContainer);
	})
	
	
	
    return(
        <section class="svg_plan" style={{display: "block"}}>
			
			<div class="def_ds svg_ds dec_square">
				<div class="ds_anim">
					<span>До моря</span>
					<b>120</b>
					<p>минут</p>
				</div>
			</div>
			<div class="svg_scroll">
				<div class="swipe"></div>
				<div class="svg_wrap">
					<div class="svg_info">
						<span class="leased_info">дом сдан  </span>
						{/* <span class="building_info">скоро сдача</span> */}
						<span class="start_info">в продаже</span>
					</div>
					<div class="info_ar info_ar_sea"><img src="img/svg_info_ar.svg" />Море <span>120 мин</span></div>
					<div class="info_ar info_ar_center"><img src="img/svg_info_ar.svg" />Центр <span>15 мин</span></div>

					<div class="lit_ident lit_1_left leased" data-liter='1'>1 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_2_left leased" data-liter='2'>2 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_3_left leased" data-liter='3'>3 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_4_left leased" data-liter='4'>4 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_5_left leased" data-liter='5'>5 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_6_left leased" data-liter='6'>6 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_7_left leased" data-liter='7'>7 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_8_left leased" data-liter='8'>8 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_9_left leased" data-liter='9'>9 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_10_left leased" data-liter='10'>10 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_11_left leased" data-liter='11'>11 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>


					<div class="lit_ident lit_1_right leased" data-liter='1'>1 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_2_right leased" data-liter='2'>2 л <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_3_right leased" data-liter='3'>3 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_4_right leased" data-liter='4'>4 л <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_5_right leased" data-liter='5'>5 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_8_right leased" data-liter='8'>8 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_11_right leased" data-liter='11'>11 к <span class="lit_dop_info"><i>Сдан досрочно</i></span></div>
					<div class="lit_ident lit_7_right leased" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='7'>7 л <span class="lit_dop_info"><i>Сдан </i></span></div>
					<div class="lit_ident lit_10_right leased" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='10'>10 л <span class="lit_dop_info"><i>Сдан </i></span></div>

					{/* <div class="lit_ident lit_6_right building" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='6'>6 л <span class="lit_dop_info"><i>Сдача 2 кв 2021 </i></span></div>
					<div class="lit_ident lit_9_right building" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='9'>9 л <span class="lit_dop_info"><i>Сдача 2 кв 2021 </i></span></div>
					<div class="lit_ident lit_12_right building" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='12'>12 л <span class="lit_dop_info"><i>Сдача 2 кв 2021 </i></span></div>
					<div class="lit_ident lit_13_right building" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='13'>13 л <span class="lit_dop_info"><i>Сдача 2 кв 2021 </i></span></div>
					<div class="lit_ident lit_14_right building" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='14'>14 л <span class="lit_dop_info"><i>Сдача 2 кв 2021 </i></span></div> */}

					
					<div class="lit_ident lit_6_right leased"  data-liter='6'>6 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_9_right leased" data-liter='9'>9 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_12_right leased" data-liter='12'>12 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_13_right leased" data-liter='13'>13 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_14_right leased" data-liter='14'>14 л <span class="lit_dop_info"><i>Сдан</i></span></div>



					<div class="lit_ident lit_19_right leased" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='19'>19 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_20_right leased" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='20'>20 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_21_right leased" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='21'>21 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_22_right leased" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='22'>22 л <span class="lit_dop_info"><i>Сдан</i></span></div>
					<div class="lit_ident lit_15_right start" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='15'>15 к <span class="lit_dop_info"><i>В продаже</i></span></div>
					<div class="lit_ident lit_16_right start" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='16'>16 к <span class="lit_dop_info"><i>В продаже</i></span></div>
					<div class="lit_ident lit_17_right start" onMouseEnter={contHover} onMouseLeave={contLeave} onClick={contClick} data-liter='17'>17 к <span class="lit_dop_info"><i>В продаже</i></span></div>
					<div class="svg_infra_ident svg_parking svg_parking_1"><p></p><span>Парковка</span></div>
					<div class="svg_infra_ident svg_parking svg_parking_2"><p></p><span>Парковка</span></div>
					<div class="svg_infra_ident svg_parking svg_parking_3"><p></p><span>Парковка</span></div>
					<div class="svg_infra_ident svg_parking svg_parking_4"><p></p><span>Парковка</span></div>
					<div class="svg_infra_ident svg_bus_stop"><p></p><span>Остановка</span></div>
					<div class="svg_infra_ident svg_school"><p></p><span>Школа</span></div>
					<div class="svg_infra_ident svg_kd"><p></p><span>Детский сад</span></div>
					<div class="svg_infra_ident svg_store"><p></p><span>Торговый центр</span></div>
					
					<img src="img/svg_plan2.jpg" />
					<div id="paths_plan"></div>
				</div>
			</div>
			
						
			
		</section>
    )
}