import {React, useEffect, useState} from "react"
import Slick from "react-slick"
import Slider from '@material-ui/core/Slider';
var loading = false
export const Flats = ()=>{
	const [flats_a, setFlats_a] = useState([]);
	
	const [flats, setFlats] = useState([])
	const [area, setArea] = useState([23, 75])
	const [floor, setFloor] = useState([3, 17])

	let url = "https://этосветлоград.рф/flats_react.php"
	const headers = { 'Content-Type': 'application/json' }
	useEffect(()=> {
		if (!loading){
			loading=true
			fetch(url, headers)
			.then(res => res.json())
			.then((result)=>{
				setFlats_a(result)
				setFlats(result)
			})
		}	
	},[])
	
	/*const slide = () => {
	})*/

	const roomsClick = (e)=>{
		e.preventDefault()
		let rooms = e.target.getAttribute('data-klass')!='all'?parseInt(e.target.getAttribute('data-klass')):e.target.getAttribute('data-klass')
		let area_min = area[0]
		let area_max = area[1]
		let sdacha = document.querySelector('.ga_completion_date span.act').getAttribute("data")=='all'?'all':parseInt(document.querySelector('.ga_completion_date span.act').getAttribute("data"))
		document.querySelectorAll('.ga_rooms span').forEach((element)=> {
            element.classList.remove('act')
        })
		e.target.classList.add('act')
		let flats_t = []
		if (flats_a.length>0){
			for(let i=0; i<flats_a.length; i++) {
				

				if(rooms!="all"){
					if (parseInt(flats_a[i].class)!==rooms) continue
				}

				let floor_min = parseInt(flats_a[i].floors.split('-')[0])
				let floor_max = parseInt(flats_a[i].floors.split('-')[1])
				if(floor[0]<floor_min || floor[0]>floor_max) continue
				
				if (parseFloat(flats_a[i]['info'])<area_min || parseFloat(flats_a[i]['info'])>area_max) continue
				if (sdacha!='all'){
					if (parseInt(flats_a[i]['sdacha'])!==sdacha) continue
				}
				
				flats_t.push(flats_a[i])
			}
			setFlats(flats_t)
		}
		
	}

	const yearsClick = (e) => {
		e.preventDefault()
		let rooms = document.querySelector('.ga_rooms .act').getAttribute('data-klass')!='all'?parseInt(document.querySelector('.ga_rooms .act').getAttribute('data-klass')):document.querySelector('.ga_rooms .act').getAttribute('data-klass')
		let area_min = area[0]
		let area_max = area[1]
		let sdacha = e.target.getAttribute("data")=='all'?'all':parseInt(e.target.getAttribute("data"))
		document.querySelectorAll('.ga_completion_date span').forEach((element)=> {
            element.classList.remove('act')
        })
		e.target.classList.add('act')
		let flats_t = []
		if (flats_a.length>0){
			for(let i=0; i<flats_a.length; i++) {
				
				if(rooms!="all"){
					if (parseInt(flats_a[i].class)!==rooms) continue
				}
				
				let floor_min = parseInt(flats_a[i].floors.split('-')[0])
				let floor_max = parseInt(flats_a[i].floors.split('-')[1])
				if(floor[0]<floor_min || floor[0]>floor_max) continue

				if (parseFloat(flats_a[i]['info'])<area_min || parseFloat(flats_a[i]['info'])>area_max) continue
				if (sdacha!='all'){
					if (parseInt(flats_a[i]['sdacha'])!==sdacha) continue
				}
				flats_t.push(flats_a[i])
			}
			setFlats(flats_t)
		}
	}
	let slides_html = <div className="noVariants">К сожалению, по выбранным вами параметрам ничего нет. Попробуйте изменить параметры фильтра.</div>

	

	

	const flatsChoose = (e) => {
		e.preventDefault()
		let flat = e.target.getAttribute('data-id')
		let popup = "pu_flat"
		flats_a.forEach((el)=> {
			if (flat == el.id){
				document.querySelector(".popup_main.pu_flat .pu_tm").innerHTML = el["title"]
				document.querySelector(".popup_main.pu_flat .fl_total_area i").innerHTML = el["info"]
				document.querySelector(".popup_main.pu_flat .fl_living_area i").innerHTML = el["zhil"]
				document.querySelector(".popup_main.pu_flat .pfi_in img").setAttribute("src", el["img_prew"])
				let liter = ""
				if (el["img_prew"].split('/')[2].split('_')[0] == "L")
					liter= el["img_prew"].split('/')[2].split('_')[1]; 
				else 
					liter= el["img_prew"].split('/')[3].split('_')[1]
				document.querySelector(".popup_main.pu_flat input[type='hidden']").value = "Узнать стоимость квартиры. "+el["title"]+"; Литер:"+liter+"; Общая площадь: "+el["info"]+" м2; жилая площадь: "+el["zhil"]+" м2;"
				
				document.querySelector('.popup_rgba').style.display="block"
				document.querySelectorAll('.popup_rgba img').forEach((el)=> {
					if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
						el.setAttribute('src', el.getAttribute('data-src'))
					}
				})
				document.querySelectorAll(".popup_main").forEach((el)=> el.style.display="none")
			
				document.querySelector(".popup_main."+popup).style.display="block"
				document.querySelector("body").classList.add("overflow")

				return
			}
		})
		
	}
	if (flats.length>0){
		slides_html = flats.map((flat)=> {
			return(<div>
				<div class="ga_inner" data-area={flat["info"]} data-class={flat["class"]} data-sdan={flat["sdacha"]}>
					<div class="gai_tit">{flat["title"]}</div>
					<div class="gai_img"><img onClick={flatsChoose} data-id={flat["id"]} class="" style={{opacity: "1"}} src={flat["img_prew"]} /></div>
					<div class="gai_area">
						<span class="total_area">
							<div class="ga_name">Общая</div>
							<span>{flat["info"]}</span>
						</span>
						<span class="living_area">
							<div class="ga_name">Жилая</div>
							<span>{flat["zhil"]}</span>
						</span>
					</div>
					<a href="" class="ga_btn" onClick={flatsChoose} data-id={flat["id"]} tabindex="0">
						Узнать стоимость
					</a>
				</div>
			</div>)
		})
	}

	var settings = {
        dots: false,
        infinite: true,
        speed: 500,
		slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: false,
		lazyLoad: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 620,
          settings: {
            slidesToShow: 1
          }
        }
        ]
	};

	
	
	
	const handleChangeArea = (event, newValue) => {
		
		let rooms = document.querySelector('.ga_rooms .act').getAttribute('data-klass')!='all'?parseInt(document.querySelector('.ga_rooms .act').getAttribute('data-klass')):document.querySelector('.ga_rooms .act').getAttribute('data-klass')
		let area_min = newValue[0]
		let area_max = newValue[1]
		let sdacha = document.querySelector('.ga_completion_date span.act').getAttribute("data")=='all'?'all':parseInt(document.querySelector('.ga_completion_date span.act').getAttribute("data"))

		let flats_t = []
		if (flats_a.length>0){
			for(let i=0; i<flats_a.length; i++) {
				
				if(rooms!="all"){
					if (parseInt(flats_a[i].class)!==rooms) continue
				}

				let floor_min = parseInt(flats_a[i].floors.split('-')[0])
				let floor_max = parseInt(flats_a[i].floors.split('-')[1])
				if(floor[0]<floor_min || floor[0]>floor_max) continue

				if (parseFloat(flats_a[i]['info'])<area_min || parseFloat(flats_a[i]['info'])>area_max) continue
				if (sdacha!='all'){
					if (parseInt(flats_a[i]['sdacha'])!==sdacha) continue
				}
				flats_t.push(flats_a[i])
			}
			setFlats(flats_t)
		}
		setArea(newValue)
	
	}

	const handleChangeFloor = (event, newValue_)=>{
		
		let rooms = document.querySelector('.ga_rooms .act').getAttribute('data-klass')!='all'?parseInt(document.querySelector('.ga_rooms .act').getAttribute('data-klass')):document.querySelector('.ga_rooms .act').getAttribute('data-klass')
		let sdacha = document.querySelector('.ga_completion_date span.act').getAttribute("data")=='all'?'all':parseInt(document.querySelector('.ga_completion_date span.act').getAttribute("data"))

		let flats_t = []
		if (flats_a.length>0){
			for(let i=0; i<flats_a.length; i++) {
				
				if(rooms!="all"){
					if (parseInt(flats_a[i].class)!==rooms) continue
				}
				
				let floor_min = parseInt(flats_a[i].floors.split('-')[0])
				let floor_max = parseInt(flats_a[i].floors.split('-')[1])
				if(newValue_[0]<floor_min || newValue_[0]>floor_max) continue

				if (parseFloat(flats_a[i]['info'])<area[0] || parseFloat(flats_a[i]['info'])>area[1]) continue
				if (sdacha!='all'){
					if (parseInt(flats_a[i]['sdacha'])!==sdacha) continue
				}

				flats_t.push(flats_a[i])
			}
			setFlats(flats_t)
		}
		setFloor(newValue_)
	}
	


	
    return (
        <section className="get_apart" id="plans">
			<div className="tm">
				<div className="tm_in">
					<span>Выберите свою<br /> будущую квартиру</span>
					<div className="ga_ds ga_ds_mob def_ds dec_square">
						<div className="ds_anim">
							<p> Более 70 <br /> планировочных <br /> решений</p>
						</div>	
					</div>
					<p> От студий до больших квартир</p>	
				</div>
			</div>
			<div className="ga_ds def_ds dec_square">
				<div className="ds_anim">
					<p> Более 70 <br /> планировочных <br /> решений</p>
				</div>	
			</div>
			<div className="ga_nav">
				<div className="ga_rooms">
					<div className="ga_name">Комнаты</div>
					<span onClick={roomsClick} data-klass='all' className="act">Все</span>
					<span onClick={roomsClick} data-klass='0'>Студии</span>
					<span onClick={roomsClick} data-klass='1'>1</span>
					<span onClick={roomsClick} data-klass='2'>2</span>
					<span onClick={roomsClick} data-klass='3'>3</span>
				</div>
				<div className="ga_slid">
				<form className="ga_form">
					<div className="ga_name">Площадь, кв. м.</div>
					<input type="text" name="" value={"от "+area[0].toFixed(2)} className="area_min" />
					<input type="text" name="" value={"до "+area[1].toFixed(2)} className="area_max" />
					{/*<div className="slid slid_style"></div>*/}
					<Slider className="slid_style_react"
						defaultValue={area}
						aria-labelledby="range-slider"
						onChange={handleChangeArea}
						step={0.2}
						min={17.95}
						max={83.05}
					/>
				</form>
				</div>
				<div className="ga_floor">
					<form className="ga_form">
						<div className="ga_name">Этаж</div>
						<input type="text" name="" value={"с "+floor[0]} className="floor_min" />
						<input type="text" name="" value={"по "+floor[1]} className="floor_max" />
						{/*<div className="plans_floor_slid slid_style"></div>*/}
						<Slider className="slid_style_react"
							defaultValue={floor}
							aria-labelledby="range-slider2"
							step={1}
							min={2}
							max={19}
							onChange={handleChangeFloor}
						/>
					</form>					
				</div>
				<div className="ga_completion_date">
					<div className="ga_name">Срок сдачи</div>
					<span onClick={yearsClick} className="act" data="all">Все</span>
					<span onClick={yearsClick} data="0000">Сданы</span>
					<span onClick={yearsClick} data="2022">2022</span>
					<span onClick={yearsClick} data="2023">2023</span>
				</div>
			</div>
			{flats.length>0?
			<Slick  className="ga_slider" {...settings} slidesToShow={flats.length>4?4:flats.length}>
				{slides_html}
			</Slick>:slides_html}
		</section>
    )
}