import {React} from "react"
export const HowToBuy = ()=> {
	const showPopup = (e) =>{
        e.preventDefault()
        let popup = e.target.getAttribute('data-popup')
        document.querySelector('.popup_rgba').style.display="block"
        document.querySelectorAll('.popup_rgba img').forEach((el)=> {
            if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
                el.setAttribute('src', el.getAttribute('data-src'))
            }
        })
        document.querySelectorAll(".popup_main").forEach((el)=> el.style.display="none")
      
        document.querySelector(".popup_main."+popup).style.display="block"
        document.querySelector("body").classList.add("overflow")
	}
	
    return(
        <section className="how_buy" id="payment">
			<div className="tm">
				<div className="tm_in">
					<span>Как можно<br /> приобрести</span>
					<p>Выбирайте подходящий<br /> вариант оплаты, а мы<br /> поможем оформить сделку</p>
					<div className="def_ds dec_square">
						<div className="ds_anim">
							<span>Более</span>
							<b>15</b>
							<p> банков- <br /> партнеров</p>
						</div>
					</div>	
				</div>
			</div>
			<div className="hb_list">
				<div className="hb_sect hb_ipot">
					<div className="hb_wrap">
						<div className="hb_tit">
							<img src="img/hb_ipot.svg" />
							<span>Ипотека</span>
						</div>
						<div className="hb_text">Во многих банках</div>
					</div>
					<a href="#" className="hb_btn show_popup" onClick={showPopup} data-popup="pu_calc">Узнать подробнее</a>
				</div>
				<div className="hb_sect hb_rassr">
					<div className="hb_wrap">
						<div className="hb_tit">
							<img src="img/hb_rassr.svg" />
							<span>Рассрочка</span>
						</div>
						<div className="hb_text">До момента сдачи дома</div>
					</div>
					<a href="#" className="hb_btn show_popup" onClick={showPopup} data-popup="pu_rassr">Узнать подробнее</a>
				</div>
				<div className="hb_sect hb_mat_kap">
					<div className="hb_wrap">
						<div className="hb_tit">
							<img src="img/hb_mat_kap.svg" />
							<span>Материнский<br /> капитал</span>
						</div>
					</div>
					<a href="#" className="hb_btn show_popup" onClick={showPopup} data-popup="pu_matkap">Узнать подробнее</a>
				</div>
				<div className="hb_sect hb_voen_ipot">
					<div className="hb_wrap">
						<div className="hb_tit">
							<img src="img/hb_voen_ipot.svg" />
							<span>Военная<br /> ипотека</span>
						</div>
					</div>
					<a href="#" className="hb_btn show_popup" onClick={showPopup} data-popup="pu_voen">Узнать подробнее</a>
				</div>
			</div>		
			<div className="clr"></div>
		</section>
    )
}