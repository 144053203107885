import {React, useState, useRef, useEffect} from "react"
import Slider from "react-slick"

export const Comfort = () =>{
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    //i dont seem to need this
    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, []);
   

    var settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,
        centerMode: true,
        centerPadding: '0px',
        
    };
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false
    };

    return(
        <section class="comfortable_living">
			<div class="cl_wrapper">
				<div class="cl_left">
					<div class="comf_title">Выгодное<br /> местоположение</div>
					<p>Удобное расположение комплекса, доехать до центра города можно за 15 минут, а до моря всего за 2 часа.</p> 
					<p>На территории комплекса находится новая школа и детский сад. А для прогулок на свежем воздухе идеально подойдет Ботанический сад.</p>
				</div>
				<div class="cl_right">
					<Slider className="cl_nav_slid"
                        {...settings}
                        asNavFor={nav2} ref={slider1}
                        focusOnSelect={true}
                        style={{zIndex:100}}
                    >
						<div>
							<div class="cln_inner">
								До центра города 
								<img src="img/cln_act_ico.svg" class="cln_act_ico" />
							</div>
						</div>
						<div>
							<div class="cln_inner">
								До моря
								<img src="img/cln_act_ico.svg" class="cln_act_ico" />
							</div>
						</div>
						<div>
							<div class="cln_inner">
								До КубГАУ
								<img src="img/cln_act_ico.svg" class="cln_act_ico" />
							</div>
						</div>
						<div>
							<div class="cln_inner">
								До Ботанического сада
								<img src="img/cln_act_ico.svg" class="cln_act_ico" />
							</div>
						</div>
						<div>
							<div class="cln_inner">
								До аэропорта
								<img src="img/cln_act_ico.svg" class="cln_act_ico" />
							</div>
						</div>
						<div>
							<div class="cln_inner">
								До ТРЦ "Красная Площадь"
								<img src="img/cln_act_ico.svg" class="cln_act_ico" />
							</div>
						</div>
					</Slider>
					<Slider className="cl_slid"
                        asNavFor={nav1}
                        ref={slider2}
                    
                        swipeToSlide={true}
                       
                    >
						<div>
							<div class="cls_inner">
								<img src="img/gallery/cl_img/cl_img_1.jpg" />
								<div class="def_ds cl_ds dec_square">
									<b>15</b>
									<p>минут</p>
								</div>
							</div>
						</div>
						<div>
							<div class="cls_inner">
								<img src="img/gallery/cl_img/cl_img_2.jpg" />
								<div class="def_ds cl_ds dec_square">
									<b>2</b>
									<p>Часа</p>
								</div>
							</div>
						</div>
						<div>
							<div class="cls_inner">
								<img src="img/gallery/cl_img/cl_img_3.jpg" />
								<div class="def_ds cl_ds dec_square">
									<b>12</b>
									<p>минут</p>
								</div>
							</div>
						</div>
						<div>
							<div class="cls_inner">
								<img src="img/gallery/cl_img/cl_img_4.jpg" />
								<div class="def_ds cl_ds dec_square">
									<b>10</b>
									<p>минут</p>
								</div>
							</div>
						</div>
						<div>
							<div class="cls_inner">
								<img src="img/gallery/cl_img/cl_img_5.jpg" />
								<div class="def_ds cl_ds dec_square">
									<b>45</b>
									<p>минут</p>
								</div>
							</div>
						</div>
						<div>
							<div class="cls_inner">
								<img src="img/gallery/cl_img/cl_img_6.jpg" />
								<div class="def_ds cl_ds dec_square">
									<b>15</b>
									<p>минут</p>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</div>
		</section>
    )
}