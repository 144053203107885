import { React, useEffect, useState, useContext } from 'react';
import { Header } from './header';
import { Childhood } from './childhood';
import { Menu } from './menu';
import { School } from './school';
import { Parking } from './parking';
import { Gallery } from './gallery';
import { Plan } from './plan';
import { Infra } from './infra';
import { LocMap } from './locMap';
import { Comfort } from './comfort';
import { Viewing } from './viewing';
import { Popups } from './popups';
import { Flats } from './flats';
import { Advantages } from './advantages';
import { Details } from './details';
import { GetFlat } from './getFlat';
import { HowToBuy } from './howToBuy';
import { Developer } from './developer';
import { Actions } from './actions';
import { HodStr } from './hodStr';
import { Contacts } from './contacts';
import { BlocksContext } from '../context/blocksContext';

export const Loader = () => {
    //const [loaded, setLoaded] = useState(2)
    const blocks = [
        <Menu />,
        // <Popups />,
        <Header />,
        <Childhood />,
        <School />,
        <Gallery />,
        <Parking />,
        <Infra />,
        <LocMap />,
        <Plan />,
        <Comfort />,
        <Viewing />,
        <Flats />,
        <Advantages />,
        <Details />,
        <GetFlat />,
        <HowToBuy />,
        <Developer />,
        <HodStr />,
        // <Actions />,
        <Contacts />
    ];
    const loaded = useContext(BlocksContext);
    const generateHtml = () => {
        let toDraw = [];
        for (let i = 0; i < loaded.blocks; i++) {
            toDraw.push(blocks[i]);
        }
        return (
            <div className="blocks" data={loaded.menuClick ? 'true' : ''}>
                <Popups />
                {toDraw.map((elem) => {
                    return elem;
                })}
            </div>
        );
    };
    const handleScroll = (event) => {
        if (loaded.blocks < blocks.length) {
            if (
                window.innerHeight / 2 + window.scrollY >
                document.querySelector('.blocks section:last-child').offsetTop
            ) {
                loaded.setPopup(true);
                let tmp = loaded.blocks + 1;
                if (tmp == 10) tmp = 11;
                window.removeEventListener('scroll', handleScroll, true);
                if (document.querySelector('.blocks').getAttribute('data') != 'true') loaded.setBlocks(tmp);
            }
        } else {
            window.removeEventListener('scroll', handleScroll, true);
        }
    };
    useEffect(() => {
        if (loaded.blocks < blocks.length) {
            window.addEventListener('scroll', handleScroll, true);
        }
    });
    return generateHtml();
};
